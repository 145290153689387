import { Box, ButtonBase, Checkbox, FormControlLabel, FormGroup, Grid, Stack, Typography, Backdrop, CircularProgress, MenuItem, Switch } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { tabs } from './data/tabs.js';
import { DefaultSelect } from '../../ui/DefaultSelect/index.js';
import { DefaultInput } from '../../ui/DefaultInput/index.js';
import { CheckCircle } from '@mui/icons-material';
import { DefaultToggle } from '../../ui/DefaultToggle/DefaultToggle.jsx';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import api from '../../lib/axios.js';
import { HeaderMenu } from '../../components/HeaderMenu.jsx';
import { enqueueSnackbar } from 'notistack';
import { arrayRegisteredState } from './data/RegisteredState.jsx';
import { ConfirmAlert } from '../../customControls/confirmAlert.js';
import { SearchInput } from '../../components/SearchInput/SearchInput.jsx';
import { DefaultDataGrid } from '../../ui/DefaultDataGrid/DefaultDataGrid.jsx';
import { assignedSensorColumns, trackerColumns } from './data/tracker.jsx';
import { PlusIcon } from '../../assets/icons/PlusIcon.jsx';
import { arrayManufacturer, arrayProduct } from './data/arrayData.jsx';

export const NodeNew = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedDataId = location.state != null && location.state.id != null ? location.state.id : ""
    const [isLoading, setIsLoading] = useState(false);
    const [arrayCategory, setArrayCategory] = useState([]);
    const [arrayType, setArrayType] = useState([]);
    const [arrayAllTrackers, setArrayAllTrackers] = useState([]);
    const [arrayAvailableTrackers, setArrayAvailableTrackers] = useState([]);
    const [arraySelectedNode, setArraySelectedNode] = useState([]);
    const [arrayGroups, setArrayGroups] = useState([]);
    const [activeTab, setActiveTab] = useState(tabs[0].value);
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    const [query, setQuery] = useState("");
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [selectedNodeID, setSelectedNodeID] = useState(null);
    const [billingActivate, setBillingActivate] = useState(false);
    // const [arrayManufacturer, setArrayManufacturer] = useState([]);
    // const [arrayProduct, setArrayProduct] = useState([]);
    const formik = useFormik({
        initialValues: {
            name: '',
            category: null,
            type: null,
            allGroups: false,
            selectedGroups: null,
            color: null,
            groups: [],
            description: '',
            firstName: '',
            lastName: '',
            department: '',
            title: '',
            email: '',
            cellular: '',
            address1: '',
            address2: '',
            city: '',
            state: null,
            zipCode: '',
            minTemperature: '',
            maxTemperature: '',
            minSpeed: '',
            maxSpeed: '',
            selectedNode: null,
            isBillingStatus: false,
            selectedManufacturer: null,
            selectedProduct: null,
            nodeID: '',
            isEnable: false,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().max(255).required('Node name is required'),
            category: Yup.object().required('Category is required'),
            type: Yup.object().required('Type is required'),
            email: Yup.string().email('Must be a valid email'),
            selectedManufacturer: Yup.object().required('Manufacturer is required'),
            selectedProduct: Yup.object().required('Product is required'),
            nodeID: Yup.string().required('Node Id is required'),
            //selectedNode: Yup.object().required('Primary tracker is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {

                var dataValues = {
                    name: values.name,
                    category: values.category,
                    type: values.type,
                    allGroups: false,
                    selectedGroups: null,
                    color: null,
                    groups: values.groups,
                    description: values.description,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    department: values.department,
                    title: values.title,
                    email: values.email,
                    cellular: values.cellular,
                    address1: values.address1,
                    address2: values.address2,
                    city: values.city,
                    state: values.state,
                    zipCode: values.zipCode,
                    // minTemperature: values.selectedNode.minTemperature,
                    // maxTemperature: values.selectedNode.maxTemperature,
                    // minSpeed: values.selectedNode.minSpeed,
                    // maxSpeed: values.selectedNode.maxSpeed,
                    selectedNode: values.selectedNode,
                    selectedManufacturer: values.selectedManufacturer,
                    selectedProduct: values.selectedProduct,
                    nodeID: values.nodeID,
                    isBillingStatus: values.isBillingStatus,
                    isEnable: values.isEnable,
                }
                formik.setValues(dataValues)
                setIsLoading(true)
                console.log("SetValues values:: ", values);
                var data = await apicalling(values);
                setIsLoading(false)
                if (data !== undefined && data.data.success) {
                    setStatus({ success: true });
                    setSubmitting(false);
                    enqueueSnackbar('Node ' + (selectedDataId == "" ? "added" : "updated"), {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'success'
                    });
                    navigate(-1)
                } else {
                    setStatus({ success: false });
                    setSubmitting(false);
                    enqueueSnackbar(data.data.message, {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                }
                setSubmitting(false);
            } catch (err) {
                console.log("inCATCH", err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);

            }
        },
    });

    useEffect(() => {
        getAllDetail()
    }, []);

    const getAllDetail = () => {
        setIsLoading(true)
        api.get('/apis/Nodes/getDetail/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                //var arrayAvailableTrackers = resData.arrayTracker.filter((tracker) => tracker.asset == null)
                setArrayCategory(resData.arrayCategory)
                setArrayType(resData.arrayType)
                setArrayGroups(resData.arrayGroup)
                setArrayAllTrackers(resData.arraySensor)
                // setArrayAvailableTrackers(arrayAvailableTrackers)
                if (selectedDataId != "") {
                    getData(resData.arrayCategory, resData.arrayType)
                } else {
                    // setArrayAvailableTrackers(resData.arraySensor)
                }
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getData = (arrayCategory, arrayType) => {
        setIsLoading(true)
        api.get('/apis/Nodes/byId/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var selectedCategory = arrayCategory.find((category) => category._id == resData.nodeCategoryID)
                var selectedType = arrayType.find((type) => type._id == resData.nodeTypeID)
                var selectedState = arrayRegisteredState.find(status => status.value == resData.state)
                var selectedNode = null
                var arraySelectedNode = []
                var selectedManufacturer = null
                var selectedProduct = null
                if (resData.sensor != null) {
                    selectedManufacturer = arrayManufacturer.find(manufacturer => manufacturer.id == resData.sensor.manufacturer)
                    selectedProduct = arrayProduct.find(product => product.id == resData.sensor.product)

                    var sensor = { ...resData.sensor }
                    selectedNode = sensor
                    arraySelectedNode.push(resData.sensor)
                    setSelectedNodeID(resData.sensor._id)
                }
                setArraySelectedNode(arraySelectedNode)
                console.log("manufacturer", selectedManufacturer);
                console.log("produvt", selectedManufacturer);
                var values = {
                    name: resData.nodeName,
                    category: selectedCategory,
                    type: selectedType,
                    allGroups: resData.groups,
                    groups: resData.groups,
                    inMaintenance: true,
                    ready: false,
                    inInspection: false,
                    scheduled: true,
                    damaged: false,
                    description: resData.description,
                    firstName: resData.firstName,
                    lastName: resData.lastName,
                    department: resData.department,
                    title: resData.title,
                    email: resData.email,
                    cellular: resData.mobile,
                    address1: resData.address1,
                    address2: resData.address2,
                    city: resData.city,
                    state: selectedState,
                    zipCode: resData.zipCode,
                    // minTemperature: selectedNode.minTemperature,
                    // maxTemperature: selectedNode.maxTemperature,
                    // minSpeed: selectedNode.minSpeed,
                    // maxSpeed: selectedNode.maxSpeed,
                    selectedNode: selectedNode,
                    selectedManufacturer: selectedManufacturer,
                    selectedProduct: selectedProduct,
                    nodeID: resData.sensorNumber,
                    isBillingStatus: resData.billingStatus == "1" ? true : false,
                    isEnable: false,
                }
                formik.setValues(values);

            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const goToNextTab = () => {
        const activeTabIndex = tabs.findIndex((tab) => tab.value === activeTab);
        formik.validateForm().then((errors) => {
            formik.setErrors(errors);
            formik.setTouched({ name: true, category: true, type: true, selectedNode: true });

            if (activeTab === tabs[0].value) {
                if (errors.name || errors.category || errors.type) {
                    console.log("err")
                } else {
                    if (activeTabIndex < tabs.length - 1) {
                        setActiveTab(tabs[activeTabIndex + 1].value);
                    }
                }
            }
            else {
                if (activeTabIndex < tabs.length - 1) {
                    setActiveTab(tabs[activeTabIndex + 1].value);
                }
            }
        });
    };

    const goToPrevTab = () => {
        const activeTabIndex = tabs.findIndex((tab) => tab.value === activeTab);

        if (activeTabIndex > 0) {
            setActiveTab(tabs[activeTabIndex - 1].value);
        }
    };

    async function apicalling(values) {
        if (values.selectedNode != null) {
            var data = {
                nodeName: values.name,
                nodeCategoryID: values.category._id,
                nodeCategory: values.category.name,
                nodeType: values.type.name,
                nodeTypeID: values.type._id,
                sensorNumber: values.selectedNode.sensorNumber,
                sensorNumberID: values.selectedNode._id,
                groups: values.groups,
                description: values.description,
                firstName: values.firstName,
                lastName: values.lastName,
                department: values.department,
                title: values.title,
                email: values.email,
                mobile: values.cellular,
                address1: values.address1,
                address2: values.address2,
                city: values.city,
                state: values.state != null ? values.state.value : "",
                zipCode: values.zipCode,
                billingStatus: values.isBillingStatus == true ? "1" : "2"
            }
            if (selectedDataId != "") {
                console.log("IF");
                return await api.put('/apis/Nodes/' + selectedDataId, data)
            } else {
                console.log("ELSE");
                return await api.post('/apis/Nodes/', data)
            }
        } else {
            enqueueSnackbar('Please add sensor  ', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }

    }

    const handleColorChange = (selectedColor) => {
        formik.setFieldValue('groups', selectedColor);
    };

    function deleteAsset() {
        setIsLoading(true)
        api.delete('/apis/Nodes/' + selectedDataId).then((res) => {
            if (res.data.success) {
                enqueueSnackbar('Node deleted', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                navigate(-2)
            } else {
                setIsLoading(false)
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deleteAsset()
        } else {
            setDeleteConfirmationShouldOpen(false)
        }
    }
    const handleQueryChange = (event) => {
        setQuery(event.target.value)
        var filterArray = applyFilters(arrayAvailableTrackers, event.target.value)
        setFilteredArrayData(filterArray)
    };

    const applyFilters = (arrayDataAll, query) => {
        if (query.length <= 3) {
            return []
        }
        var finalArray = []
        var filteredArraySearch = arrayDataAll
            .filter((dataObj) => {
                let matches = true;
                const properties = ['deviceSerialNumber'];
                let containsQuery = false;
                properties.forEach((property) => {
                    var str = dataObj[property] + ""
                    if (str.toLowerCase().includes(query.toLowerCase())) {
                        containsQuery = true;
                    }
                });
                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : []
        return finalArray
    }

    function handleAssignedNode(node) {
        if (arraySelectedNode.length >= 1) {
            enqueueSnackbar('Only one tracker can be added ', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } else {
            var objTracker = { ...node }
            var arraySelectedDevice = [...arraySelectedNode]
            arraySelectedDevice.push(objTracker)
            setArraySelectedNode(arraySelectedDevice);
            formik.setFieldValue('selectedNode', node);
        }
    }

    function handleUnassignedNode(tracker) {
        var objTracker = { ...tracker }
        setArrayAvailableTrackers(objTracker);
        setArraySelectedNode([])
        setSelectedNodeID(null)
        formik.setFieldValue('selectedNode', null);
    }

    const addNode = () => {
        formik.validateForm().then((errors) => {
            formik.setErrors(errors);
            formik.setTouched({ selectedManufacturer: true, selectedProduct: true, nodeID: true });
            if (errors.selectedManufacturer || errors.selectedProduct || errors.nodeID) {
                // Return early to stop further execution
                return;
            } else {
                setIsLoading(true)
                var data = {
                    sensorNumber: formik.values.nodeID,
                    manufacturer: formik.values.selectedManufacturer ? formik.values.selectedManufacturer.id : "",
                    product: formik.values.selectedProduct ? formik.values.selectedProduct.id : ""
                }
                api.post('/apis/NodeSensors/add', data).then((res) => {
                    if (res.data.success) {
                        var resData = res.data.data
                        // setArrayCategory(resData.arrayCategory)
                        handleAssignedNode(resData)
                        setSelectedNodeID(resData._id)
                    } else {
                        enqueueSnackbar(res.data.message, {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'error'
                        });
                    }
                    setIsLoading(false)
                }).catch(function (error) {
                    console.log(error);
                });
            }
        });

    }
    const deleteNode = (nodeData) => {
        setIsLoading(true)
        api.delete('/apis/NodeSensors/deleteById/' + selectedNodeID).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                handleUnassignedNode(nodeData)
                // formik.setFieldValue('selectedManufacturer', '')
                // formik.setFieldValue('selectedProduct', '')
                // formik.setFieldValue('nodeID', '')
                getData(arrayCategory, arrayType)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }
    return (
        <FormikProvider value={formik}>
            <Stack
                flex={1}
                p={3}
                gap={3}
                overflow={'hidden'}
                component={'form'}
                onSubmit={formik.handleSubmit}
            >
                <Stack direction={'row'} gap={3} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography textTransform={'uppercase'}>NODE SETUP</Typography>
                </Stack>
                <Typography>Follow these quick steps to setup or edit an node in the system.</Typography>
                <Stack direction={'row'} overflow={'hidden'} width={'100%'}>
                    {tabs.map((tab, idx) => (
                        <Stack
                            direction={'row'}
                            gap={1}
                            key={tab.value}
                            px={2}
                            py={1}
                            border={'1px solid #E2E2EA'}
                            borderRight={idx === tabs.length - 1 ? '1px solid #E2E2EA' : 'none'}
                            flex={1}
                            position={'relative'}
                            sx={{
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    bottom: '0',
                                    left: 0,
                                    width: '100%',
                                    height: 3,
                                    bgcolor: 'secondary.main',
                                    display: activeTab === tab.value ? 'block' : 'none',
                                },
                            }}
                        >
                            <Typography>{idx + 1}.</Typography>
                            <Stack gap={0.5} overflow={'hidden'} flex={1}>
                                <Stack direction={'row'} gap={1} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography>{tab.title}</Typography>
                                    {tabs.findIndex((tab) => tab.value === activeTab) !== idx &&
                                        tabs.findIndex((tab) => tab.value === activeTab) > idx && (
                                            <CheckCircle
                                                sx={{
                                                    width: 24,
                                                    height: 24,
                                                    color: 'green.main',
                                                }}
                                            />
                                        )}
                                </Stack>
                                <Typography
                                    fontSize={12}
                                    color={'#AAB0BC'}
                                    overflow={'hidden'}
                                    whiteSpace={'nowrap'}
                                    textOverflow={'ellipsis'}
                                >
                                    {tab.text}
                                </Typography>
                            </Stack>
                        </Stack>
                    ))}
                    <Stack
                        py={1}
                        px={5}
                        alignItems={'center'}
                        justifyContent={'center'}
                        border={'1px solid #E2E2EA'}
                        flexShrink={0}
                    >
                        <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                border: '1px solid #E2E2EA',
                                bgcolor: '#FCFCFE',
                                borderRadius: '5px',
                                height: '30px'
                            }}
                            type='submit'
                            disabled={formik.isSubmitting}
                            onClick={(e) => { console.log("error", formik.errors); }}
                        >
                            <Typography color={'secondary.main'} fontSize={14} fontWeight={700} whiteSpace={'nowrap'}>
                                Save & Close
                            </Typography>
                        </ButtonBase>
                    </Stack>
                </Stack>

                <Stack direction={'row'} gap={3} justifyContent={'space-between'} alignItems={'flex-start'}>
                    {activeTab === tabs[0].value && (
                        <Stack gap={1}>
                            <Typography>Basic Setup Details</Typography>
                            <Typography fontSize={14} color={'#AAB0BC'}>
                                Enter the basic details of the sensor node including it’s desired name, category and type.
                            </Typography>
                        </Stack>
                    )}
                    {/* {activeTab === tabs[1].value && (
                        <Stack gap={1}>
                            <Typography>Node Details</Typography>
                            <Typography fontSize={14} color={'#AAB0BC'}>
                                Enter the applicable asset details and description.
                            </Typography>
                        </Stack>
                    )} */}
                    {activeTab === tabs[1].value && (
                        <Stack
                            direction={'row'}
                            gap={1}
                            alignItems={'flex-end'}
                            justifyContent={'space-between'}
                            maxWidth={650}
                            width={'100%'}
                        >
                            <Stack gap={1}>
                                <Typography>Contact Details</Typography>
                                <Typography fontSize={14} color={'#AAB0BC'}>
                                    Enter the applicable contact details for this asset.
                                </Typography>
                            </Stack>
                            <Stack direction={'row'} gap={1} alignItems={'center'} mb={-1}>
                                <Typography fontSize={14}>Contact Details</Typography>
                                <DefaultToggle name={'isEnable'} />
                            </Stack>
                        </Stack>
                    )}
                    {activeTab === tabs[2].value && (
                        <Stack >
                            <Typography>Assign a Sensor Node</Typography>
                            {/* <Typography fontSize={14} color={'#AAB0BC'}>
                                Enter the basic details of the asset including it’s desired name, category and type.
                            </Typography> */}
                        </Stack>
                    )}
                    <Stack direction={'row'} gap={1}>
                        {selectedDataId != "" &&
                            <ButtonBase
                                sx={{
                                    py: 1,
                                    px: 3,
                                    border: '1px solid #E2E2EA',
                                    borderRadius: '5px',
                                    bgcolor: '#E2E2EA',
                                    mt: 'auto',
                                    alignSelf: 'flex-end',
                                    height: '30px'
                                }}
                                onClick={(e) => {
                                    setDeleteConfirmationShouldOpen(true)
                                }}
                            >
                                <Typography fontSize={14}>Remove</Typography>
                            </ButtonBase>
                        }
                        <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                border: '1px solid #E2E2EA',
                                bgcolor: '#E2E2EA',
                                borderRadius: '5px',
                                height: '30px'
                            }}
                            onClick={() => {
                                navigate(-1)
                                // if (selectedDataId != "") {
                                //     if (arraySelectedNode.length == 0 ) {
                                //         enqueueSnackbar("At least one node is required", {
                                //             anchorOrigin: { horizontal: "right", vertical: "top" },
                                //             variant: "error",
                                //         });
                                //     } else {
                                //         navigate(-1);
                                //     }
                                // } else {
                                //     navigate(-1);
                                // }
                            }}
                        >
                            <Typography fontSize={14}>Cancel</Typography>
                        </ButtonBase>
                        <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                border: '1px solid #E2E2EA',
                                borderRadius: '5px',
                                bgcolor: tabs.findIndex((tab) => tab.value === activeTab) === 0 && '#f0f0f3',
                                height: '30px'
                            }}
                            disabled={tabs.findIndex((tab) => tab.value === activeTab) === 0}
                            onClick={goToPrevTab}
                        >
                            <Typography fontSize={14} color={tabs.findIndex((tab) => tab.value === activeTab) === 0 && '#9a9aa4'}>
                                Back
                            </Typography>
                        </ButtonBase>
                        <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                border: '1px solid #E2E2EA',
                                borderRadius: '5px',
                                bgcolor: tabs.findIndex((tab) => tab.value === activeTab) === tabs.length - 1 && '#f0f0f3',
                                height: '30px'
                            }}
                            onClick={goToNextTab}
                            disabled={tabs.findIndex((tab) => tab.value === activeTab) === tabs.length - 1}
                        >
                            <Typography
                                fontSize={14}
                                color={tabs.findIndex((tab) => tab.value === activeTab) === tabs.length - 1 && '#9a9aa4'}
                            >
                                Next
                            </Typography>
                        </ButtonBase>
                    </Stack>
                </Stack>
                {tabs[0].value === activeTab && (
                    <Box bgcolor={'#FAFBFD'} px={3} py={2} flex={1}>
                        <Stack gap={3} maxWidth={650}>
                            <Box>
                                <Grid spacing={3} container>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Node Name'}
                                            value={formik.values.name}
                                            name={'name'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.name && formik.errors.name)}
                                            helperText={formik.touched.name && formik.errors.name}
                                        />
                                    </Grid>
                                    <Grid xs={6} item >
                                        <Stack direction={'row'} gap={2} alignItems={'center'} mt={2}>
                                            <DefaultToggle name={'isBillingStatus'} />
                                            <Typography
                                                fontWeight={700}
                                                sx={{
                                                    fontSize: '15px',
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                {formik.values.isBillingStatus == true ? "Active billing" : "Inactive billing"}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Box position={'relative'}>
                                            <DefaultSelect
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: 40,
                                                    },
                                                    // whiteSpace: 'nowrap',
                                                    // overflow: 'hidden',
                                                    // textOverflow: 'ellipsis', // Truncate long text with ellipsis
                                                }}
                                                label={'Category'}
                                                value={formik.values.category}
                                                onItemSelected={(e) => {
                                                    formik.setFieldValue('category', e.target.value)
                                                    formik.setFieldValue('type', null)
                                                }}
                                                helperText={formik.touched.category && formik.errors.category}
                                                error={Boolean(formik.touched.category && formik.errors.category)}
                                                // placeholder="Select category"
                                                displayKey="name"
                                            >
                                                {arrayCategory.map((item, index) => (
                                                    <MenuItem key={index} value={item}> {item.name}</MenuItem>
                                                ))}
                                            </DefaultSelect>
                                        </Box>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Box position={'relative'}>
                                            <DefaultSelect
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: 40,
                                                    },
                                                }}
                                                label={'Type'}
                                                value={formik.values.type}
                                                onItemSelected={(e) => formik.setFieldValue('type', e.target.value)}
                                                helperText={formik.touched.type && formik.errors.type}
                                                error={Boolean(formik.touched.type && formik.errors.type)}
                                                // placeholder="Select type"
                                                displayKey="name"
                                            >
                                                {formik.values.category != null && arrayType.filter(obj => obj.nodeCategoryID == formik.values.category._id).map((item, index) => (
                                                    <MenuItem key={index} value={item}> {item.name}</MenuItem>
                                                ))}
                                            </DefaultSelect>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} item>
                                        <Box position={'relative'}>
                                            <DefaultInput
                                                sx={{
                                                    '& .MuiInputBase-input': {
                                                        pr: 6,
                                                    },
                                                }}
                                                label="Description"
                                                multiline
                                                rows={4}
                                                // value={formik.values.description}
                                                name="description"
                                            // onBlur={formik.handleBlur}
                                            // onChange={formik.handleChange}
                                            // error={Boolean(formik.touched.description && formik.errors.description)}
                                            // helperText={formik.touched.description && formik.errors.description}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box height={'1px'} bgcolor={'#E2E2EA'} />
                            <Stack gap={1}>
                                <Typography>Groups</Typography>
                                <Typography fontSize={12} color={'#AAB0BC'}>
                                    Assign to one or more groups to help manage your node. To Edit Groups to go Settings &gt; Groups.
                                </Typography>
                            </Stack>
                            <Stack gap={1}>
                                <Typography fontSize={14}>Configured Groups</Typography>
                                <FormGroup
                                    sx={{
                                        '.MuiFormControlLabel-label': {
                                            color: '#696974',
                                            fontSize: 14,
                                        },
                                        '.MuiCheckbox-root': {
                                            color: '#B5B5BE',

                                            '&.Mui-checked': {
                                                color: '#44444F',
                                            },
                                        },
                                    }}
                                >
                                    <Stack px={2} ml={-2}>
                                        {arrayGroups.length > 0 &&
                                            <Stack
                                                direction={'row'}
                                                gap={3}
                                                maxWidth={300}
                                                justifyContent={'space-between'}
                                                alignItems={'center'}
                                            >
                                                <FormControlLabel
                                                    name={'allGroups'}
                                                    control={
                                                        <Checkbox
                                                            checked={formik.values.groups != null ? arrayGroups.length == formik.values.groups.length : false}
                                                            onChange={(e) => {
                                                                var groups = formik.values.groups ? formik.values.groups : []
                                                                if (arrayGroups.length == groups.length) {
                                                                    groups = []
                                                                } else {
                                                                    groups = arrayGroups.map(data => data._id)
                                                                }
                                                                handleColorChange(groups)
                                                            }}
                                                        />
                                                    }
                                                    label='All Groups'
                                                />
                                            </Stack>
                                        }
                                        {arrayGroups.map((group, index) => (
                                            <Stack
                                                key={index}
                                                direction={'row'}
                                                gap={3}
                                                maxWidth={300}
                                                justifyContent={'space-between'}
                                                alignItems={'center'}
                                            >
                                                <FormControlLabel
                                                    key={index}
                                                    value={group.name}
                                                    control={
                                                        <Checkbox
                                                            checked={formik.values.groups != null ? formik.values.groups.includes(group._id) : false}
                                                            onChange={(e) => {
                                                                var groups = formik.values.groups ? formik.values.groups : []
                                                                if (groups.includes(group._id)) {
                                                                    groups = groups.filter(obj => obj != group._id)
                                                                } else {
                                                                    groups.push(group._id)
                                                                }
                                                                handleColorChange(groups)
                                                            }}
                                                        />
                                                    }
                                                    label={group.name}
                                                />
                                                <Box width={45} height={23} bgcolor={group.color} borderRadius={'5px'} />
                                            </Stack>
                                        ))}
                                    </Stack>
                                </FormGroup>
                            </Stack>
                        </Stack>
                    </Box>
                )}
                {tabs[1].value === activeTab && (
                    <Box bgcolor={'#FAFBFD'} px={3} py={2} flex={1}>
                        <Stack gap={3} maxWidth={650}>
                            <Box>
                                <Grid spacing={3} container>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'First Name'}
                                            value={formik.values.firstName}
                                            name={'firstName'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.firstName && formik.errors.firstName)}
                                            helperText={formik.touched.firstName && formik.errors.firstName}
                                            disabled={!formik.values.isEnable}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Last Name'}
                                            value={formik.values.lastName}
                                            name={'lastName'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.lastName && formik.errors.lastName)}
                                            helperText={formik.touched.lastName && formik.errors.lastName}
                                            disabled={!formik.values.isEnable}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Department'}
                                            value={formik.values.department}
                                            name={'department'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.department && formik.errors.department)}
                                            helperText={formik.touched.department && formik.errors.department}
                                            disabled={!formik.values.isEnable}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Title'}
                                            value={formik.values.title}
                                            name={'title'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.title && formik.errors.title)}
                                            helperText={formik.touched.title && formik.errors.title}
                                            disabled={!formik.values.isEnable}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Email'}
                                            value={formik.values.email}
                                            name={'email'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.email && formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                            disabled={!formik.values.isEnable}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Cellular'}
                                            value={formik.values.cellular}
                                            name={'cellular'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.cellular && formik.errors.cellular)}
                                            helperText={formik.touched.cellular && formik.errors.cellular}
                                            disabled={!formik.values.isEnable}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Address 1'}
                                            value={formik.values.address1}
                                            name={'address1'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.address1 && formik.errors.address1)}
                                            helperText={formik.touched.address1 && formik.errors.address1}
                                            disabled={!formik.values.isEnable}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Address 2'}
                                            value={formik.values.address2}
                                            name={'address2'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.address2 && formik.errors.address2)}
                                            helperText={formik.touched.address2 && formik.errors.address2}
                                            disabled={!formik.values.isEnable}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'City'}
                                            value={formik.values.city}
                                            name={'city'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.city && formik.errors.city)}
                                            helperText={formik.touched.city && formik.errors.city}
                                            disabled={!formik.values.isEnable}
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Box position={'relative'}>
                                            <DefaultSelect
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: 40,
                                                    },
                                                }}
                                                label={'State'}
                                                value={formik.values.state}
                                                onItemSelected={(e) => {
                                                    formik.setFieldValue('state', e.target.value)
                                                }}
                                                helperText={formik.touched.state && formik.errors.state}
                                                error={Boolean(formik.touched.state && formik.errors.state)}
                                                disabled={!formik.values.isEnable}
                                            // placeholder="Select state"
                                            >
                                                {arrayRegisteredState.map((item, index) => (
                                                    <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                                ))}

                                            </DefaultSelect>
                                        </Box>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <DefaultInput
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    pr: 6,
                                                },
                                            }}
                                            label={'Zip Code'}
                                            value={formik.values.zipCode}
                                            name={'zipCode'}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.touched.zipCode && formik.errors.zipCode)}
                                            helperText={formik.touched.zipCode && formik.errors.zipCode}
                                            disabled={!formik.values.isEnable}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Stack>
                    </Box>
                )}
                {tabs[2].value === activeTab && (
                    <>
                        <Stack spacing={2} flex={1}>
                            <Stack>
                            </Stack>
                            {/* <Stack
                                px={3}
                                py={2}
                                width={'100%'}
                                sx={{
                                    border: '1px solid #E2E2EA',
                                    borderRadius: '8px',
                                    borderRightWidth: '1px',
                                }}
                                overflow={'hidden'}
                                position={'relative'}

                            >
                                <Stack direction={'row'} ml={-1.5}>
                                    <SearchInput name={'search'} handleQueryChange={handleQueryChange} />
                                </Stack>
                                <Stack direction={'row'} ml={-1.5} mt={2}>
                                    <DefaultDataGrid columns={trackerColumns(handleAssignedTracker)} rows={filteredArrayData} />
                                </Stack>
                            </Stack> */}
                            {selectedNodeID == null && <Stack gap={3} maxWidth={650}>
                                <Typography fontWeight={700} fontSize={14}>Enter Node ID (Serial)</Typography>
                                <Box>
                                    <Grid spacing={3} container>
                                        <Grid xs={6} item>
                                            <DefaultSelect
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: 40,
                                                    },
                                                }}
                                                label={'Manufacturer'}
                                                value={formik.values.selectedManufacturer}
                                                onItemSelected={(e) => {
                                                    formik.setFieldValue('selectedManufacturer', e.target.value)
                                                }}
                                                helperText={formik.touched.selectedManufacturer && formik.errors.selectedManufacturer}
                                                error={Boolean(formik.touched.selectedManufacturer && formik.errors.selectedManufacturer)}
                                                displayKey="value"
                                            >
                                                {arrayManufacturer.map((item, index) => (
                                                    <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                                ))}
                                            </DefaultSelect>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Box position={'relative'}>
                                                <DefaultSelect
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            height: 40,
                                                        },
                                                    }}
                                                    label={'Product'}
                                                    value={formik.values.selectedProduct}
                                                    onItemSelected={(e) => formik.setFieldValue('selectedProduct', e.target.value)}
                                                    helperText={formik.touched.selectedProduct && formik.errors.selectedProduct}
                                                    error={Boolean(formik.touched.selectedProduct && formik.errors.selectedProduct)}
                                                    displayKey="value"
                                                >
                                                    {formik.values.selectedManufacturer != null && arrayProduct.filter(obj => obj.id == formik.values.selectedManufacturer.id).map((item, index) => (
                                                        <MenuItem key={index} value={item}> {item.value}</MenuItem>
                                                    ))}
                                                </DefaultSelect>
                                            </Box>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Stack position={'relative'}>
                                                <DefaultInput
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            pr: 6,
                                                        },
                                                    }}
                                                    label={'Node ID'}
                                                    value={formik.values.nodeID}
                                                    name={'nodeID'}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    error={Boolean(formik.touched.nodeID && formik.errors.nodeID)}
                                                    helperText={formik.touched.nodeID && formik.errors.nodeID}
                                                ></DefaultInput>
                                                <CheckCircle
                                                    sx={{
                                                        position: 'absolute',
                                                        right: 10,
                                                        top: 24,
                                                        width: 30,
                                                        height: 30,
                                                        color: 'green.main',
                                                    }}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <ButtonBase
                                                sx={{
                                                    height: 30,
                                                    px: 2,
                                                    py: 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    mt: 2.8,
                                                    gap: 1,
                                                    bgcolor: 'green.main',
                                                    borderRadius: '6px',
                                                }}
                                                onClick={addNode}
                                            >
                                                <PlusIcon />
                                                <Typography fontSize={14} color={'common.white'}>
                                                    Add
                                                </Typography>
                                            </ButtonBase>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Stack>}
                            <Stack>
                                <Typography fontWeight={700} fontSize={14}>Assigned Node</Typography>
                            </Stack>
                            <Stack
                                px={3}
                                py={2}
                                width={'100%'}
                                sx={{
                                    border: '1px solid #E2E2EA',
                                    borderRadius: '8px',
                                    borderRightWidth: '1px',
                                }}
                                overflow={'hidden'}
                                position={'relative'}
                                mb={3}
                            >
                                <Stack direction={'row'} ml={-1.5}>
                                    <DefaultDataGrid columns={assignedSensorColumns(deleteNode)} rows={arraySelectedNode}
                                    // onRowClick={(e) => { onTrackerClick(e.row) }}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    </>
                )}
            </Stack>
            {
                deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                    updateConfirmAlert={(e, value) => {
                        setDeleteConfirmationShouldOpen(false)
                        updateConfirmAlert(value)
                    }} />)
            }
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </FormikProvider >
    );
};
