import { Avatar, Box, Stack, Typography, } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import batteryIcon from '../../assets/images/batteryIcon.svg';
import redBatteryIcon from '../../assets/images/redBatteryIcon.svg';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export const Dashboard = ({ data, index }) => {
    const navigate = useNavigate();

    useEffect(() => {
    }, []);


    return (
        <Stack sx={{ overflow: 'hidden', width: '196px', height: '194px' }}
            onClick={() => { navigate(`/personnel-listing/detail`, { state: { id: data._id } }) }}
        >
            <Box
                sx={{
                    height: "100%",
                    width: "100%",
                    bgcolor: data.state == "1" ? "#FFFFFF" : "#FEF8F8",
                    borderRadius: "5px",
                    border: '1px solid ',
                    borderColor: "#D5D5D5",
                }}
            >
                <Stack height={'100%'} width={'100%'}>
                    <Stack direction={'row'} justifyContent={'center'} width={'100%'} height={'100%'} pl={'11px'} pr={'10px'} pt={'9px'} alignItems={'center'} pb={'6px'}>
                        <Stack justifyContent={'space-between'} height={'100%'} width={'100%'}>
                            <Typography fontSize={'15px'} color={'#151D26'} fontWeight={400} fontFamily={'Roboto'}
                                sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',

                                }}
                            >
                                {data != null && data.firstName && data.lastName ? `${data.firstName} ${data.lastName[0]}.` : "-"}
                            </Typography>


                            <Avatar
                                alt="User Avatar"
                                src={data != null && data.path ? data.path : ""}
                                sx={{
                                    width: 100,
                                    height: 100
                                }}>

                            </Avatar>

                            <Stack direction={"row"} width={'100%'} alignItems={'center'} gap={'22px'}>
                                <Box
                                    sx={{
                                        height: '16px',
                                        width: '16px',
                                    }}
                                >
                                    {data.isFavourite == "1" && <CheckBoxIcon sx={{
                                        height: '16px',
                                        width: '16px',
                                    }} />}
                                </Box>
                                <Typography fontSize={15} color={'#151D26'} fontWeight={400} fontFamily={'Roboto'} sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}>
                                    {data.temperature && !isNaN(data.temperature) ? `${parseFloat(data.temperature).toFixed(1)} °F` : "-"}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Box flexGrow={1} />
                        <Stack gap={'10px'} height={'100%'} justifyContent={'space-between'}>
                            <Stack direction={'row'} alignItems={'center'} gap={'10px'} mt={'4px'}>
                                <Typography fontSize={10} color={"#151D26"} fontWeight={400} fontFamily={'Roboto'} >
                                    {data.state == "1" ? "Active" : "Offline"}
                                </Typography>
                                <Box
                                    sx={{
                                        width: 8,
                                        height: 8,
                                        borderRadius: '50%',
                                        backgroundColor: data.state == "1" ? '#1AAB87' : "#D34C46",
                                    }}
                                />
                            </Stack>
                            <Stack gap={'7px'}>
                                <Typography fontSize={15} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'}  >
                                    {data.totalEvent || 0}
                                </Typography>
                                <Typography fontSize={10} color={'#151D26'} fontWeight={400} fontFamily={'Roboto'} >
                                    Events
                                </Typography>
                            </Stack>
                            <Stack gap={'7px'}>
                                <Typography fontSize={15} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'}  >
                                    {data != null && data.light ? `${parseInt(data.light)}%` : 0}
                                </Typography>
                                <Typography fontSize={10} color={'#151D26'} fontWeight={400} fontFamily={'Roboto'} >
                                    Light
                                </Typography>
                            </Stack>
                            <Stack direction={'row'} gap={'10px'} alignItems={'center'} mt={'10px'}>
                                <Typography fontSize={'15px'} color={'#151D26'} fontWeight={400} fontFamily={'Roboto'} >
                                    {data.battery != "-" && data.battery ? `${parseInt(data.battery)}%` : "-"}
                                </Typography>
                                <Box component={'img'} src={data.battery != "-" && data.battery ? (parseFloat(data.battery).toFixed(1) < 3.5 ? redBatteryIcon : batteryIcon) : batteryIcon} sx={{ height: '18px', width: '18px', rotate: data.battery != "-" && data.battery ? (parseFloat(data.battery).toFixed(1) < 3.5 ? '0deg' : '-90deg') : '-90deg' }} />
                            </Stack>
                        </Stack>
                    </Stack>
                    <Box sx={{
                        height: "6px",
                        width: "100%",
                        bgcolor: data.isEvent == "1" ? "#3333CC" : data.state == "1" ? '#1AAB87' : "#D34C46",
                        borderRadius: "0px 0px 5px 5px",
                    }}>

                    </Box>
                </Stack>

            </Box >
        </Stack >
    )
}