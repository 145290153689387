import { useEffect, useState } from "react"
import { Filter } from "../../ui/Filter"
import { Stack, Box, MenuItem, Grid, Typography, Checkbox, Backdrop, CircularProgress } from "@mui/material"
import { SearchInput } from '../../components/SearchInput/index.js';
import api from "../../lib/axios"
import settingSliderIcon from '../../assets/images/settingSliderIcon.svg';
import { arrayMeasurment } from "../RuleNew/data/arrayData";

export const AssetMapViewFilter = ({ setMapViewData, mapViewData, filterMapViewData, setFiterMapViewData, isTimeline, isTotals, isPersonnel, isAsset, isNodes, setIsTimline, setIsTotals, setIsPersonnel, setIsAsset, setIsNodes, }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [query, setQuery] = useState("");
    const [isFilter, setIsFilter] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(-1)
    const [selectedGroup, setSelectedGroup] = useState(-1)
    const [selectedEvent, setSelectedEvent] = useState(-2)
    const [arrayGroups, setArrayGroups] = useState([]);
    const assetStatus = ['Idle', 'Moving', 'Active', 'Offline'];

    useEffect(() => {
        getAssetAllDetail()
        const fetchData = () => {
            getMapViewData()
        };
        setIsLoading(true)
        // fetchData();

        const intervalId = setInterval(() => {
            console.log('30 seconds have passed. Fetching data again...');
            setIsLoading(false)
            fetchData();
        }, 30000);

        return () => clearInterval(intervalId);

    }, []);


    const getAssetAllDetail = () => {
        // setIsLoading(true)
        api.get('/apis/Assets/getMapViewFilterDetail/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayGroups(resData.arrayGroup)
            }
            getMapViewData()
            // setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }


    const getMapViewData = () => {
        // setIsLoading(true)
        api.get('/apis/Assets/mapView/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                console.log("***", resData)
                var filterArrayAsset = applyAssetFilters(resData, query, selectedStatus, selectedGroup, selectedEvent)
                var filterArrayNode = applyNodeFilters(resData, query, selectedStatus, selectedGroup, selectedEvent)
                var filterArrayPersonnel = applyPersonnelFilters(resData, query, selectedStatus, selectedGroup, selectedEvent)
                console.log("***", filterArrayAsset.length, filterArrayNode.length, filterArrayPersonnel.length)
                var obj = {
                    arrayAsset: filterArrayAsset,
                    arrayNode: filterArrayNode,
                    arrayPersonnel: filterArrayPersonnel
                }
                setFiterMapViewData(obj)
                setMapViewData(resData)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const applyAssetFilters = (data, query, selectedStatus, selectedGroup, selectedEvent) => {
        if (data != null && data.arrayAsset) {
            if (query === "" && selectedStatus === -1 && selectedGroup === -1 && selectedEvent === -2) {
                return data.arrayAsset
            }
            var finalArray = []
            var filteredArraySearch = data.arrayAsset
                .filter((dataObj) => {
                    let matches = true;
                    const properties = ['assetName', 'serialNumber'];
                    let containsQuery = false;
                    properties.forEach((property) => {
                        var str = dataObj[property] + ""
                        if (str.toLowerCase().includes(query.toLowerCase())) {
                            containsQuery = true;
                        }
                    });
                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : []

            if (selectedStatus !== null && selectedStatus != -1) {
                var filteredArrayStatus = finalArray
                    .filter((dataObj) => {
                        let matches = true;
                        let containsQuery = false;
                        var speedStatus = dataObj.tracker != null && dataObj.tracker.positionSpeed != null && dataObj.tracker.positionSpeed > 0 ? "Moving" : "Idle"
                        if (selectedStatus == speedStatus) {
                            containsQuery = true;
                        }

                        if (!containsQuery) {
                            matches = false;
                        }
                        return matches;
                    });
                finalArray = [...filteredArrayStatus]
            }

            if (selectedGroup !== null && selectedGroup != -1) {
                var filteredArrayGroup = finalArray
                    .filter((dataObj) => {
                        let matches = true;
                        let containsQuery = false;
                        if (dataObj.groups != null && dataObj.groups.length > 0) {
                            if (dataObj.groups.includes(selectedGroup)) {
                                containsQuery = true;
                            }
                        }
                        if (!containsQuery) {
                            matches = false;
                        }
                        return matches;
                    });
                finalArray = [...filteredArrayGroup]
            }

            if (selectedEvent !== null && selectedEvent != -2) {
                var fliterArrayType = finalArray
                    .filter((dataObj) => {
                        let matches = true;
                        let containsQuery = false;

                        if (dataObj.eventType && dataObj.eventType === selectedEvent) {
                            containsQuery = true;
                        }

                        if (!containsQuery) {
                            matches = false;
                        }
                        return matches;
                    });
                finalArray = [...fliterArrayType]
            }

            return finalArray
        }
    }

    const applyNodeFilters = (data, query, selectedStatus, selectedGroup, selectedEvent) => {
        if (data != null && data.arrayNode) {
            if (query === "" && selectedStatus === -1 && selectedGroup === -1 && selectedEvent === -2) {
                return data.arrayNode
            }
            var finalArray = []
            var nodefilteredArraySearch = data.arrayNode
                .filter((dataObj) => {
                    let matches = true;
                    const properties = ['nodeName', 'sensorNumber'];
                    let containsQuery = false;
                    properties.forEach((property) => {
                        var str = dataObj[property] + ""
                        if (str.toLowerCase().includes(query.toLowerCase())) {
                            containsQuery = true;
                        }
                    });
                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = nodefilteredArraySearch.length > 0 ? [...nodefilteredArraySearch] : []

            if (selectedGroup !== null && selectedGroup != -1) {
                var nodefilteredArrayGroups = finalArray
                    .filter((dataObj) => {
                        let matches = true;
                        let containsQuery = false;
                        if (dataObj.groups != null && dataObj.groups.length > 0) {
                            if (dataObj.groups.includes(selectedGroup)) {
                                containsQuery = true;
                            }
                        }
                        if (!containsQuery) {
                            matches = false;
                        }
                        return matches;
                    });
                finalArray = [...nodefilteredArrayGroups]
            }

            if (selectedStatus !== null && selectedStatus != -1) {
                var nodefilteredArrayStatus = finalArray
                    .filter((dataObj) => {
                        let matches = true;
                        let containsQuery = false;
                        var speedStatus = dataObj.sensor != null && dataObj.sensor.state != null && dataObj.sensor.state == "1" ? "Active" : "Offline"
                        if (selectedStatus == speedStatus) {
                            containsQuery = true;
                        }

                        if (!containsQuery) {
                            matches = false;
                        }
                        return matches;
                    });
                finalArray = [...nodefilteredArrayStatus]
            }

            if (selectedEvent !== null && selectedEvent != -2) {
                var fliterArrayType = finalArray
                    .filter((dataObj) => {
                        let matches = true;
                        let containsQuery = false;

                        if (dataObj.eventType && dataObj.eventType === selectedEvent) {
                            containsQuery = true;
                        }

                        if (!containsQuery) {
                            matches = false;
                        }
                        return matches;
                    });
                finalArray = [...fliterArrayType]
            }

            return finalArray
        }
    }

    const applyPersonnelFilters = (data, query, selectedStatus, selectedGroup, selectedEvent) => {
        if (data != null && data.arrayPersonnel) {
            if (query === "" && selectedStatus === -1 && selectedGroup === -1 && selectedEvent === -2) {
                return data.arrayPersonnel
            }
            var finalArray = []
            var nodefilteredArraySearch = data.arrayPersonnel
                .filter((dataObj) => {
                    let matches = true;
                    const properties = ['firstName', 'lastName', 'sensorNumber'];
                    let containsQuery = false;
                    properties.forEach((property) => {
                        var str = dataObj[property] + ""
                        if (str.toLowerCase().includes(query.toLowerCase())) {
                            containsQuery = true;
                        }
                    });
                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = nodefilteredArraySearch.length > 0 ? [...nodefilteredArraySearch] : []

            if (selectedGroup !== null && selectedGroup != -1) {
                var nodefilteredArrayGroups = finalArray
                    .filter((dataObj) => {
                        let matches = true;
                        let containsQuery = false;
                        if (dataObj.groups != null && dataObj.groups.length > 0) {
                            if (dataObj.groups.includes(selectedGroup)) {
                                containsQuery = true;
                            }
                        }
                        if (!containsQuery) {
                            matches = false;
                        }
                        return matches;
                    });
                finalArray = [...nodefilteredArrayGroups]
            }

            if (selectedStatus !== null && selectedStatus != -1) {
                var nodefilteredArrayStatus = finalArray
                    .filter((dataObj) => {
                        let matches = true;
                        let containsQuery = false;
                        var speedStatus = dataObj.sensor != null && dataObj.sensor.state != null && dataObj.sensor.state == "1" ? "Active" : "Offline"
                        if (selectedStatus == speedStatus) {
                            containsQuery = true;
                        }

                        if (!containsQuery) {
                            matches = false;
                        }
                        return matches;
                    });
                finalArray = [...nodefilteredArrayStatus]
            }



            if (selectedEvent !== null && selectedEvent != -2) {
                var fliterArrayType = finalArray
                    .filter((dataObj) => {
                        let matches = true;
                        let containsQuery = false;

                        if (dataObj.eventType && dataObj.eventType === selectedEvent) {
                            containsQuery = true;
                        }

                        if (!containsQuery) {
                            matches = false;
                        }
                        return matches;
                    });
                finalArray = [...fliterArrayType]
            }
            return finalArray
        }
    }

    const handleQueryChange = (event) => {
        setQuery(event.target.value)
        var filterArrayAsset = applyAssetFilters(mapViewData, event.target.value, selectedStatus, selectedGroup, selectedEvent)
        var filterArrayNode = applyNodeFilters(mapViewData, event.target.value, selectedStatus, selectedGroup, selectedEvent)
        var filterArrayPersonnel = applyPersonnelFilters(mapViewData, event.target.value, selectedStatus, selectedGroup, selectedEvent)
        var obj = {
            arrayAsset: filterArrayAsset,
            arrayNode: filterArrayNode,
            arrayPersonnel: filterArrayPersonnel
        }
        setFiterMapViewData(obj)
    };

    return (
        <Stack>
            <Stack ml={'10px'} mr={'10px'} mt={'16px'} mb={'5px'} direction={'row'} gap={'10px'}>
                <SearchInput
                    name={'search'}
                    handleQueryChange={handleQueryChange}
                    sx={{ minWidth: '200px' }} />
                <Box
                    sx={{
                        border: '1px solid #E2E2EA',
                        bgcolor: '#F6F8FA',
                        borderRadius: '5px',
                        height: '30px',
                        width: "30px",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor:'pointer'
                    }}
                    onClick={(e) => {
                        setIsFilter(!isFilter)
                    }}
                >
                    <Box component={'img'} src={settingSliderIcon} sx={{ width: '20px', height: '16px' }}></Box>
                </Box>
            </Stack>
            {isFilter &&
                <Stack ml={'10px'} mt={'5px'} mr={'10px'} gap={'10px'}>
                    <Filter
                        sx={{
                            '.MuiSelect-select': {
                                py: '5px',
                                fontSize: 12,
                                pl: 2,
                            },
                        }}
                        onChange={(event) => {
                            var value = event.target.value
                            setSelectedGroup(value)
                            var filterArrayAsset = applyAssetFilters(mapViewData, query, selectedStatus, value, selectedEvent)
                            var filterArrayNode = applyNodeFilters(mapViewData, query, selectedStatus, value, selectedEvent)
                            var filterArrayPersonnel = applyPersonnelFilters(mapViewData, query, selectedStatus, value, selectedEvent)
                            var obj = {
                                arrayAsset: filterArrayAsset,
                                arrayNode: filterArrayNode,
                                arrayPersonnel: filterArrayPersonnel
                            }
                            setFiterMapViewData(obj)

                        }}
                        defaultValue={selectedGroup}
                        placeholder="All Groups"
                    >
                        {arrayGroups.map((item, index) => (
                            <MenuItem key={index} value={item._id}> {item.name}</MenuItem>
                        ))}
                    </Filter>
                    <Filter
                        sx={{
                            '.MuiSelect-select': {
                                py: '5px',
                                fontSize: 12,
                                pl: 2,
                            },
                        }}
                        onChange={(event) => {
                            var value = event.target.value
                            setSelectedStatus(value)
                            var filterArrayAsset = applyAssetFilters(mapViewData, query, value, selectedGroup, selectedEvent)
                            var filterArrayNode = applyNodeFilters(mapViewData, query, value, selectedGroup, selectedEvent)
                            var filterArrayPersonnel = applyPersonnelFilters(mapViewData, query, value, selectedGroup, selectedEvent)
                            var obj = {
                                arrayAsset: filterArrayAsset,
                                arrayNode: filterArrayNode,
                                arrayPersonnel: filterArrayPersonnel
                            }
                            setFiterMapViewData(obj)
                        }}
                        defaultValue={selectedStatus}
                        placeholder="All Status"
                    >
                        {assetStatus.map((item, index) => (
                            <MenuItem key={index} value={item}> {item}</MenuItem>
                        ))}
                    </Filter>
                    <Filter
                        sx={{
                            '.MuiSelect-select': {
                                py: '5px',
                                fontSize: 12,
                                pl: 2,
                            },
                        }}
                        onChange={(event) => {
                            var value = event.target.value
                            setSelectedEvent(value)
                            var filterArrayAsset = applyAssetFilters(mapViewData, query, selectedStatus, selectedGroup, value)
                            var filterArrayNode = applyNodeFilters(mapViewData, query, selectedStatus, selectedGroup, value)
                            var filterArrayPersonnel = applyPersonnelFilters(mapViewData, query, selectedStatus, selectedGroup, value)
                            var obj = {
                                arrayAsset: filterArrayAsset,
                                arrayNode: filterArrayNode,
                                arrayPersonnel: filterArrayPersonnel
                            }
                            setFiterMapViewData(obj)
                        }}
                        defaultValue={selectedEvent}
                        placeholder="All Events"
                        menuKey={-2}
                    >

                        {arrayMeasurment.map((item, index) => (
                            <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                        ))}
                    </Filter>
                </Stack>
            }

            {isFilter &&
                <Stack ml={'16px'} mt={'14px'}>
                    <Grid container xs={12} columnSpacing={2} rowSpacing={'10px'}>
                        <Grid item xs={3.8}>
                            <Stack direction={'row'} justifyContent={'space-between'} >
                                <Typography fontWeight={400} fontFamily={'Roboto'} fontSize={12} color={'#615D5D'}> Timeline</Typography>

                                <Checkbox
                                    checked={isTimeline == "1"}
                                    sx={{ width: '16px', height: '16px' }}
                                    onChange={(e) => {
                                        setIsTimline(e.target.checked)
                                        localStorage.setItem("mapViewTimeLineSelected", e.target.checked ? "1" : "0")
                                    }}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={3.8}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <Typography fontWeight={400} fontFamily={'Roboto'} fontSize={12} color={'#615D5D'}> Totals</Typography>

                                <Checkbox
                                    checked={isTotals == "1"}
                                    sx={{ width: '16px', height: '16px' }}
                                    onChange={(e) => {
                                        setIsTotals(e.target.checked)
                                        localStorage.setItem("mapViewTotalsSelected", e.target.checked ? "1" : "0")
                                    }}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={4.4}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <Typography fontWeight={400} fontFamily={'Roboto'} fontSize={12} color={'#615D5D'}> Personnel</Typography>
                                <Checkbox
                                    checked={isPersonnel == "1"}
                                    sx={{ width: '16px', height: '16px' }}
                                    onChange={(e) => {
                                        setIsPersonnel(e.target.checked)
                                        localStorage.setItem("mapViewPersonnelSelected", e.target.checked ? "1" : "0")
                                    }}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={3.8}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <Typography fontWeight={400} fontFamily={'Roboto'} fontSize={12} color={'#615D5D'}> Assets</Typography>

                                <Checkbox
                                    checked={isAsset == "1"}
                                    sx={{ width: '16px', height: '16px' }}
                                    onChange={(e) => {
                                        setIsAsset(e.target.checked)
                                        localStorage.setItem("mapViewAssetSelected", e.target.checked ? "1" : "0")
                                    }}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={3.8}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <Typography fontWeight={400} fontFamily={'Roboto'} fontSize={12} color={'#615D5D'}> Nodes</Typography>

                                <Checkbox
                                    checked={isNodes == "1"}
                                    sx={{ width: '16px', height: '16px' }}
                                    onChange={(e) => {
                                        setIsNodes(e.target.checked)
                                        localStorage.setItem("mapViewNodesSelected", e.target.checked ? "1" : "0")
                                    }}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>}

            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>
    )
}