import { Box, Stack, Typography, Grid, Table, TableRow, TableHead, TableCell, TableBody } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DefaultDataGrid } from '../../ui/DefaultDataGrid/index.js';
import { columns } from './data/reportsDetail';
import { arrayBillingCycle, arrayStatus } from '../BillingReporting/data/arrayData';
import moment from 'moment';
import { ColorStatus } from '../../ui/ColorStatus/ColorStatus.jsx';
import { arrayDeviceProduct, arrayDeviceType } from '../RuleNew/data/arrayData.jsx';


export const BillingReportDetailPrint = () => {
    const navigate = useNavigate();
    const [reportData, setReportData] = useState(null);
    const [filteredArrayData, setFilteredArrayData] = useState([]);

    useEffect(() => {
        const newWindowState = JSON.parse(localStorage.getItem('printData'));
        const { invoiceData, filteredArrayData } = newWindowState

        setReportData(invoiceData)
        setFilteredArrayData(filteredArrayData)

        setTimeout(() => {
            window.print();
        }, 1000)

    }, []);

    return (
        <>
            <Box sx={{ bgcolor: '#FFFFFF', height: 'calc(100vh - 200px)', width: '100%' }}>
                <Stack pt={3}>
                    <Stack ml={"39px"} width={"100%"}>
                        <Grid container spacing={5} alignItems="flex-start" >
                            {/* Invoice Section */}
                            <Grid item xs={12} md={5.5}>
                                <Typography fontSize={15} fontWeight={500} textTransform={'uppercase'} fontFamily={'Roboto'} >
                                    Invoice
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        <Stack mt={"30px"}>
                                            <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                                {reportData != null && reportData.invoice ? reportData.invoice : "-"}
                                            </Typography>
                                            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Invoice</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Stack mt={"30px"}>
                                            <Stack direction={'row'} alignItems={'center'} spacing={4}>
                                                <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                                    {reportData != null && reportData.status ? arrayStatus.find((status) => status.id == reportData.status)?.value : "-"}
                                                </Typography>
                                                <Box sx={{ width: 8, height: 8, borderRadius: '50%', backgroundColor: reportData != null && reportData.status ? arrayStatus.find((status) => status.id == reportData.status)?.color : "#36B368" }} />
                                            </Stack>
                                            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Status</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Stack mt={"30px"}>
                                            <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                                {reportData != null && reportData.status ? arrayBillingCycle.find((cycle) => cycle.id == reportData.status)?.value : "-"}
                                            </Typography>
                                            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Cycle</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Stack mt={"30px"}>
                                            <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                                {reportData != null && reportData.invoiceDate ? moment(reportData.invoiceDate).format("MMMM DD, YYYY") : "-"}
                                            </Typography>
                                            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Invoice Date</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                            {reportData != null && reportData.amount ? `$${parseFloat(reportData.amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "-"}
                                        </Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Amount</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                            {reportData != null && reportData.amountPaid ? `$${parseFloat(reportData.amountPaid).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "-"}
                                        </Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Amount Paid</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                            {reportData != null && reportData.balanceDue ? `$${parseFloat(reportData.balanceDue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "-"}
                                        </Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Balance Due</Typography>
                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                            {reportData != null && reportData.dueDate ? moment(reportData.dueDate).format("MMMM DD, YYYY") : "-"}
                                        </Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Due Date</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Customer Section */}
                            <Grid item xs={12} md={1.5}>
                                <Typography fontSize={15} fontWeight={500} textTransform={'uppercase'} fontFamily={'Roboto'} >
                                    Customer
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Stack mt={'30px'}>
                                            <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#151D26'}>
                                                {reportData != null && reportData.companyAddress && reportData.companyAddress.address1 ? reportData.companyAddress.address1 : ""}
                                            </Typography>
                                            <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#151D26'}>
                                                {reportData != null && reportData.companyAddress && reportData.companyAddress.address2 ? reportData.companyAddress.address2 : ""}
                                            </Typography>
                                            <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#151D26'}>
                                                {reportData != null && reportData.companyAddress && `${reportData.companyAddress.city}, ${reportData.companyAddress.state} ${reportData.companyAddress.zipCode}`}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Devices Section */}
                            <Grid item xs={12} md={5}>
                                <Typography fontSize={15} fontWeight={500} textTransform={'uppercase'} fontFamily={'Roboto'}>
                                    Devices
                                </Typography>
                                <Grid container spacing={3} >
                                    <Grid item xs={3.5}>
                                        <Stack mt={'30px'}>
                                            <Stack direction={'row'} alignItems={'center'} spacing={3}>
                                                <Typography fontSize={14} fontFamily={'Roboto'} fontWeight={400} color={'#151D26'}>
                                                    Active Devices
                                                </Typography>
                                                <Box sx={{ width: 8, height: 8, borderRadius: '50%', backgroundColor: "#36B368" }} />
                                            </Stack>
                                            <Typography fontSize={24} fontFamily={'Roboto'} fontWeight={500} color={'#151D26'}>
                                                {filteredArrayData.length > 0 ? filteredArrayData.filter((device) => (device.status == "1")).length : "-"}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Stack mt={'30px'}>
                                            <Stack direction={'row'} alignItems={'center'} spacing={3}>
                                                <Typography fontSize={14} fontFamily={'Roboto'} fontWeight={400} color={'#151D26'}>
                                                    Disabled Devices
                                                </Typography>
                                                <Box sx={{ width: 8, height: 8, borderRadius: '50%', backgroundColor: "#FF3941" }} />
                                            </Stack>
                                            <Typography fontSize={24} fontFamily={'Roboto'} fontWeight={500} color={'#151D26'}>
                                                {filteredArrayData.length > 0 ? filteredArrayData.filter((device) => (device.status == "2")).length : "-"}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Stack>
                </Stack>
                {/* <Stack flex={1} border={'1px solid #E1E3E8'} borderRadius={'10px 0 0 10px'} mt={'20px'} ml={'22px'} mb={'19px'}>
                    <DefaultDataGrid rows={filteredArrayData} columns={columns} onRowClick={(e) => navigate(`/billing-reports-listing/detail`, { state: { id: e.row._id } })} />
                </Stack> */}
                <Stack flex={1} border={'1px solid #E1E3E8'} borderRadius={'10px 0 0 10px'} mt={'20px'} ml={'22px'} mr={'22px'}>
                    <Table>
                        <TableHead>
                            <TableRow style={{ backgroundColor: "#F5F7FB" }}>
                                <TableCell style={{ borderBottom: "none" }}>
                                    <Typography
                                        variant="primary"
                                        fontSize={'15px'}
                                        fontWeight='bold'
                                        style={{ whiteSpace: 'nowrap' }}>
                                        Device ID
                                    </Typography>
                                </TableCell>

                                <TableCell style={{ borderBottom: "none" }}>
                                    <Typography
                                        variant="primary"
                                        fontSize={'15px'}
                                        fontWeight='bold'
                                        style={{ whiteSpace: 'nowrap' }}>
                                        Associated
                                    </Typography>
                                </TableCell>

                                <TableCell style={{ borderBottom: "none" }}>
                                    <Typography
                                        variant="primary"
                                        fontSize={'15px'}
                                        fontWeight='bold'>
                                        Item Type
                                    </Typography>
                                </TableCell>

                                <TableCell style={{ borderBottom: "none" }}>
                                    <Typography
                                        variant="primary"
                                        fontSize={'15px'}
                                        fontWeight='bold'
                                        style={{ whiteSpace: 'nowrap' }}>
                                        Manufacturer
                                    </Typography>
                                </TableCell>

                                <TableCell style={{ borderBottom: "none" }}>
                                    <Typography
                                        variant="primary"
                                        fontSize={'15px'}
                                        fontWeight='bold'>
                                        Product
                                    </Typography>
                                </TableCell>

                                <TableCell style={{ borderBottom: "none" }}>
                                    <Typography
                                        variant="primary"
                                        fontSize={'15px'}
                                        fontWeight='bold'>
                                        Amount
                                    </Typography>
                                </TableCell>

                                <TableCell style={{ borderBottom: "none" }}>
                                    <Typography
                                        variant="primary"
                                        fontSize={'15px'}
                                        fontWeight='bold'>
                                        Billing Status
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {reportData != null && reportData.devices != null && <TableBody>
                            {reportData != null && reportData.devices.map((data, index) => {
                                return (
                                    <TableRow key={data._id} style={{
                                        backgroundColor: index % 2 === 0 ? 'inherit' : '#F5F7FB',
                                    }}>
                                        <TableCell style={{ borderBottom: "none" }}>
                                            <Typography fontSize={'12px'}>
                                                {data.deviceSerialNumber}
                                            </Typography>
                                        </TableCell>

                                        <TableCell style={{ borderBottom: "none" }}>
                                            <Typography
                                                variant="primary"
                                                fontSize={'13px'}>
                                                {data != null && data.name ? data.name : "-"}
                                            </Typography>
                                        </TableCell>

                                        <TableCell style={{ borderBottom: "none" }}>
                                            <Typography
                                                variant="primary"
                                                fontSize={'13px'}>
                                                {data != null && data.type ? data.type == "1" ? "Asset" : "Node" : "-"}
                                            </Typography>
                                        </TableCell>

                                        <TableCell style={{ borderBottom: "none" }}>
                                            <Typography
                                                variant="primary"
                                                fontSize={'13px'}>
                                                {data != null && data.type == "1" ? "Digital Matter" : (data.sensor ? arrayDeviceProduct.find((manufacturer) => manufacturer.id == data.sensor.manufacturer)?.value : "-")}
                                            </Typography>
                                        </TableCell>

                                        <TableCell style={{ borderBottom: "none" }}>
                                            <Typography
                                                variant="primary"
                                                fontSize={'13px'}>
                                                {data.tracker != null && data.type == "1" ? (data.tracker.type ? data.tracker.type : "-") : (data.sensor ? arrayDeviceType.find((type) => type.id == data.sensor.product)?.value : "-")}

                                            </Typography>
                                        </TableCell>

                                        <TableCell style={{ borderBottom: "none" }}>
                                            <Typography
                                                variant="primary"
                                                fontSize={'13px'}>
                                                {`${data.cost != null && data.cost != "" ? `$${parseFloat(data.cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "0.00"}`}
                                            </Typography>
                                        </TableCell>

                                        <TableCell style={{ borderBottom: "none" }}>
                                            <Box
                                                sx={{
                                                    px: 2,
                                                    border: '1px solid #E2E2EA',
                                                    borderRadius: '5px',
                                                    height: '30px',
                                                    width: '100px',

                                                }}
                                            >
                                                <Stack
                                                    direction={'row'}
                                                    justifyContent={'space-between'}
                                                    alignItems={'center'}
                                                    height="100%"
                                                >
                                                    <Typography
                                                        color={'#44444F'}
                                                        fontFamily={'Roboto'}
                                                        fontWeight={'Regular'}
                                                        fontSize={12}
                                                    >
                                                        {(data.status != null && data.status == "1" ? "Active" : "Disabled")}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            width: 8,
                                                            height: 8,
                                                            borderRadius: '50%',
                                                            backgroundColor: data.status != null && data.status == "1" ? "#36B368" : "#FF3941",
                                                        }}
                                                    />
                                                </Stack>
                                            </Box>
                                        </TableCell>


                                    </TableRow>
                                )
                            })}
                        </TableBody>}
                    </Table>
                </Stack>
            </Box >
        </>
    );
};
