import logo from '../../assets/images/logo.svg';
import logoSmall from '../../assets/images/logo-small.svg';
import { SidebarItem } from '../../components/Sidebar/SidebarItem';
import { SideBarDownArrowIcon } from '../../assets/icons/SideBarDownArrowIcon';
import { ArrowIcon } from '../../assets/icons/ArrowIcon';
import { theme } from '../../core/theme/theme';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { mainMenu, systemMenu, trackingMenu } from './data/menu';
import { BurgerIcon } from '../../assets/icons/BurgerIcon.jsx';
import { useRecoilState } from 'recoil';
import { sidebarOpened } from '../../core/store/index.js';
import { Typography, Box, Stack, FormControl, Select, MenuItem, Menu } from '@mui/material';
import { DefaultAvatar } from '../../ui/DefaultAvatar/DefaultAvatar.jsx';
import { useNavigate } from 'react-router';
import useAuth from '../../hooks/useAuth';
import { ArrowDownIcon } from '../../assets/icons/ArrowDownIcon';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ConfirmAlert } from '../../customControls/confirmAlert';

export const Sidebar = () => {
    const [isOpened, setIsOpened] = useRecoilState(sidebarOpened);
    const [name, setName] = useState('ACME Trailers');
    const navigate = useNavigate()
    const { logout } = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);
    const [logoutConfirmationShouldOpen, setLogoutConfirmationShouldOpen] = useState(false);
    const open = Boolean(anchorEl);
    const toggleSidebar = () => {
        setIsOpened((prev) => {
            const newValue = !prev;
            localStorage.setItem('isSideOpen', newValue ? "1" : "0");
            return newValue;
        });
    };

    const handleChange = (event) => {
        setName(event.target.value);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleProfile = async () => {
        setAnchorEl(null);
        navigate('/profile/editProfile');
    };

    const handleChangePassword = async () => {
        setAnchorEl(null);
        navigate('/profile/changePassword');
    };

    const handleLogout = async () => {
        try {
            handleClose();
            await logout();
            navigate('/authentication/login');
        } catch (err) {
            console.log(err);
        }

    };
    const updateConfirmAlert = (value) => {
        if (value) {
            handleLogout()
        } else {
            setLogoutConfirmationShouldOpen(false)
        }
    }
    const myString = localStorage.getItem('fullName') ? localStorage.getItem('fullName') : "";
    const words = myString.split(' ');
    const firstCharacters = words.map(word => word[0]);
    return (
        <Stack
            component={'aside'}
            width={isOpened ? 250 : "80px"}
            flexShrink={0}
            bgcolor={'primary.dark'}
            boxShadow={theme.shadows[1]}
            // position={'relative'}
            zIndex={11}
            sx={{
                transition: 'all 0.3s ease',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                    width: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                    width: '4px',
                    bgcolor: 'grey.400',
                },
            }}
        >
            <Stack direction={isOpened ? 'row' : 'column'} alignItems={'center'} justifyContent={'space-between'}>
                <Box
                    p={isOpened ? 3 : 1.5}
                    sx={{
                        transition: 'all 0.3s ease',
                    }}
                >
                    <Box
                        width={isOpened ? 125 : 24}
                        height={24}
                        sx={{
                            img: {
                                width: '100%',
                                objectFit: 'contain',
                            },
                        }}
                    >
                        <Link to={'/'}>
                            {isOpened && <img src={logo} alt='logo' />}
                            {!isOpened && <img src={logoSmall} alt='logo' />}
                        </Link>
                    </Box>
                </Box>
                <Box
                    // position={'absolute'}
                    // top={isOpened ? 28 : 16}
                    // right={16}
                    width={24}
                    height={16}
                    mr={isOpened ? 1.5 : 0}
                    // display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    onClick={toggleSidebar}
                    sx={{
                        cursor: 'pointer',
                        // transition: 'all 0.3s ease',
                        svg: {
                            width: 16,
                            height: 10,
                            fill: isOpened ? '#92929D' : '#fff',
                        },
                    }}
                >
                    <BurgerIcon />
                </Box>
            </Stack>
            <Box pr={3} pl={isOpened ? 3 : 2} mt={!isOpened ? '10px' : ""}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    mb={'23px'}
                    justifyContent={isOpened ? 'space-between' : 'center'}
                >

                    <Stack onClick={handleClick} direction={'row'} alignItems={'center'} width={'100%'} sx={{ cursor: 'pointer' }}>
                        <Stack direction={'row'} alignItems={'center'} width={'100%'}>
                            <DefaultAvatar
                                sx={{
                                    width: 45,
                                    height: 45,
                                    bgcolor: '#262B40',
                                    color: '#FFFFFF',
                                    fontSize: 20,
                                }}
                            >
                                {firstCharacters.join('')}
                            </DefaultAvatar>
                            {isOpened && (
                                <Stack marginRight={2} ml={'15px'}>
                                    <Typography
                                        fontSize={14}
                                        fontWeight={400}
                                        color={'#FFFFFF'}
                                        fontFamily={'Roboto'}
                                    >
                                        {localStorage.getItem('firstName')}
                                    </Typography>
                                </Stack>
                            )}
                        </Stack>

                        {isOpened && (
                            <Stack mr={'8px'}>
                                <KeyboardArrowDownIcon sx={{ color: '#FFFFFF' }} />
                            </Stack>
                        )}
                    </Stack>
                    <Menu
                        id='basic-menu'
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleProfile}>Profile</MenuItem>
                        <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
                        <MenuItem onClick={() => {
                            setLogoutConfirmationShouldOpen(true)
                            handleClose()
                        }}>Logout</MenuItem>
                    </Menu>
                </Stack>

                {isOpened && <Stack>
                    <FormControl
                        variant='filled'
                        fullWidth
                        hiddenLabel
                        sx={{
                            mb: 1,
                            '.MuiSelect-filled': {
                                bgcolor: '#262B40',
                                borderRadius: '5px',
                                py: '10px',
                                pl: '15px',
                                color: 'common.white',
                                fontWeight: 700,
                                '&:focus': {
                                    bgcolor: '#262B40',
                                    borderRadius: '5px',
                                },
                            },
                            '.MuiFilledInput-root::before, .MuiFilledInput-root::after': {
                                display: 'none',
                            },
                        }}
                    >
                        <Select
                            sx={{
                                '& .MuiSelect-icon': {
                                    top: 'calc(50% - 4px)',
                                    right: '12px',
                                    stroke: theme.palette.common.white,
                                },
                                pointerEvents: 'none'
                            }}
                            value={name}
                            onChange={handleChange}
                            IconComponent={SideBarDownArrowIcon}
                        >
                            <MenuItem value={'ACME Trailers'}>ACME Trailers</MenuItem>
                        </Select>
                    </FormControl>

                    {/* <Typography mb={1} fontSize={13} color={'#8F98AB'} fontWeight={400} fontFamily={'Roboto'}>
                        Select Organization
                    </Typography> */}
                </Stack>}
                {/* {isOpened && <Box mt={'33px'} bgcolor={'grey.light'} height={'1px'} />} */}
            </Box>
            <Stack mt={'20px'}>
                <Box
                    px={isOpened ? 3 : 1.5}
                    sx={{
                        transition: 'all 0.3s ease',
                    }}
                    mb={'17px'}
                >
                    {isOpened && <Typography
                        color={'#8F98AB'}
                        fontSize={13}
                        fontWeight={400}
                        fontFamily={"Roboto"}
                        textAlign={!isOpened && 'center'}
                    >
                        Tracking
                    </Typography>}
                </Box>
                <Stack spacing={2.5}>
                    {trackingMenu.map((item) => (
                        <SidebarItem isOpened={isOpened} key={item.title} data={item} />
                    ))}
                </Stack>
            </Stack>
            <Stack mr={3} ml={3}>
                <Box mt={'32px'} bgcolor={'#92929D'} height={'1px'} width={isOpened ? "100px" : "100%"} />
            </Stack>
            <Stack mt={'16px'}>
                <Box
                    px={isOpened ? 3 : 1.5}
                    sx={{
                        transition: 'all 0.3s ease',
                    }}
                    mb={'17px'}
                >
                    {isOpened && <Typography
                        color={'#8F98AB'}
                        fontSize={13}
                        fontWeight={400}
                        fontFamily={"Roboto"}
                        textAlign={!isOpened && 'center'}
                    >
                        Sensing
                    </Typography>}
                </Box>
                <Stack spacing={2.5}>
                    {mainMenu.map((item) => (
                        <SidebarItem isOpened={isOpened} key={item.title} data={item} />
                    ))}
                </Stack>
            </Stack>
            <Stack mr={3} ml={3}>
                <Box mt={'33px'} bgcolor={'#92929D'} height={'1px'} width={isOpened ? "100px" : "100%"} />
            </Stack>
            <Stack mt={isOpened ? '29px' : "20px"} spacing={3} mb={'37px'}>
                <Box
                    px={isOpened ? 3 : 1.5}
                    sx={{
                        transition: 'all 0.3s ease',
                    }}
                >
                    {isOpened && <Typography
                        color={'#8F98AB'}
                        fontSize={13}
                        fontWeight={400}
                        fontFamily={'Roboto'}
                        textAlign={!isOpened && 'center'}
                    >
                        Administration
                    </Typography>}
                </Box>
                <Stack mb={3} spacing={2.5}>
                    {systemMenu.map((item) => (
                        <SidebarItem isOpened={isOpened} key={item.title} data={item} />
                    ))}
                </Stack>
            </Stack>
            {
                logoutConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to logout?"}
                    updateConfirmAlert={(e, value) => {
                        setLogoutConfirmationShouldOpen(false)
                        updateConfirmAlert(value)
                    }} />)
            }
        </Stack>
    );
};
