import { Avatar, Box, Checkbox, Stack, Typography } from '@mui/material';
import { ColorStatus } from '../../../ui/ColorStatus/index.js';
import { ArrowForwardIos } from '@mui/icons-material';
import { Tag } from '../../../ui/Tag/index.js';
import moment from 'moment';

export const columns = (calculateDuration, onCheck) => [
    {
        field: 'personnel',
        headerName: 'Personnel',
        width: 200,
        renderCell: (params) => (
            <Stack direction={'row'} alignItems={'center'} gap={3}>
                <Avatar
                    src={params.row && params.row.path ? params.row.path : ""}
                    sx={{
                        color: "primary.main",
                        backgroundColor: '#DCDCDC',
                    }}
                >
                </Avatar>
                <Typography fontSize={12}>
                    {params.row && params.row.firstName && params.row.lastName ? params.row.firstName + " " + params.row.lastName : '-'}
                </Typography>
            </Stack>
        ),
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 130,
        renderCell: (params) => {
            var status = params.row.sensor && params.row.sensor.state == "1" ? "Active" : "Offline"
            var statusColor = params.row.sensor && params.row.sensor.state == "1" ? "#2BD8A0" : "#D5D5D5"
            return (
                <Box
                    sx={{
                        width: '100px',
                        border: '1px solid',
                        borderColor: statusColor,
                        borderRadius: '20px',
                        height: '30px',
                        alignContent: 'center',
                    }}
                >
                    <Stack alignItems={'center'} width={'100%'}>
                        <Typography fontSize={12}>{status}</Typography>
                    </Stack>
                </Box>
            );
        },
    },
    {
        field: 'groups',
        headerName: 'Groups',
        flex: 1,
        renderCell: (params) => {
            var arrayGroup = params.row.groupData
            return (arrayGroup != null && arrayGroup.length > 0 ? <Stack direction={'row'} gap={1}>
                {arrayGroup.slice(0, 2).map((group, index) => (
                    <Box
                        key={group._id}
                        sx={{
                            px: 2,
                            border: '1px solid #E2E2EA',
                            borderRadius: '20px',
                            bgcolor: "#F7F7F7",
                            height: '30px',
                        }}
                    >
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            gap={2}
                            height="100%"
                        >
                            <Typography
                                color={'#44444F'}
                                fontFamily={'Roboto'}
                                fontWeight={400}
                                fontSize={12}
                            >
                                {group.name}
                            </Typography>
                            <Box
                                sx={{
                                    width: 8,
                                    height: 8,
                                    borderRadius: '50%',
                                    backgroundColor: group.color,
                                }}
                            />
                        </Stack>
                    </Box>
                ))}
                {arrayGroup.length > 2 &&
                    <Box sx={{
                        px: 2,
                        border: '1px solid #E2E2EA',
                        borderRadius: '20px',
                        height: '30px',
                        alignContent: 'center',
                        width: '54px'
                    }}

                    >
                        <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                            <Typography
                                color={'#707070'}
                                fontFamily={'Roboto'}
                                fontWeight={'Regular'}
                                fontSize={12}
                            >
                                {`${arrayGroup.length - 2}+`}
                            </Typography>
                            <ArrowForwardIos
                                sx={{
                                    color: '#151D26',
                                    width: 13,
                                    height: 13,
                                }}
                            />
                        </Stack>

                    </Box>
                }
            </Stack> : <Typography fontSize={12}> - </Typography>)
        },
        // renderCell: (params) => {
        //     var arrayGroup = params.row.groupData
        //     return (arrayGroup != null && arrayGroup.length > 0 ? <Stack direction={'row'} gap={1}>
        //         {arrayGroup.slice(0, 2).map((group, index) => (
        //             <Box
        //                 key={group._id}
        //                 sx={{
        //                     px: 2,
        //                     border: '1px solid #E2E2EA',
        //                     borderRadius: '20px',
        //                     bgcolor: "#F7F7F7",
        //                     height: '30px',
        //                 }}
        //             >
        //                 <Stack
        //                     direction={'row'}
        //                     justifyContent={'space-between'}
        //                     alignItems={'center'}
        //                     gap={2}
        //                     height="100%"
        //                 >
        //                     <Typography
        //                         color={'#44444F'}
        //                         fontFamily={'Roboto'}
        //                         fontWeight={400}
        //                         fontSize={12}
        //                     >
        //                         {group.name}
        //                     </Typography>
        //                     <Box
        //                         sx={{
        //                             width: 8,
        //                             height: 8,
        //                             borderRadius: '50%',
        //                             backgroundColor: group.color,
        //                         }}
        //                     />
        //                 </Stack>
        //             </Box>
        //         ))}
        //         {arrayGroup.length > 2 &&
        //             <Box sx={{
        //                 px: 2,
        //                 border: '1px solid #E2E2EA',
        //                 borderRadius: '20px',
        //                 height: '30px',
        //                 alignContent: 'center',
        //                 width: '54px'
        //             }}

        //             >
        //                 <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
        //                     <Typography
        //                         color={'#707070'}
        //                         fontFamily={'Roboto'}
        //                         fontWeight={'Regular'}
        //                         fontSize={12}
        //                     >
        //                         {`+${arrayGroup.length - 2}`}
        //                     </Typography>
        //                     <ArrowForwardIos
        //                         sx={{
        //                             color: '#151D26',
        //                             width: 13,
        //                             height: 13,
        //                         }}
        //                     />
        //                 </Stack>

        //             </Box>
        //         }
        //     </Stack> : <Typography fontSize={12}> - </Typography>)
        // },
    },
    {
        field: 'sensor',
        headerName: 'Light',
        width: 120,
        renderCell: (params) => (
            <Stack
                width={"100%"}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                height="100%"
                mr={6}
            >
                <Typography
                    color={'#44444F'}
                    fontFamily={'Roboto'}
                    fontWeight={400}
                    fontSize={12}
                >
                    <Typography fontSize={12}>{params.row.sensor?.light ? `${parseInt(params.row.sensor?.light)}%` : "-"}</Typography>
                </Typography>
                {params.row.sensor?.light && <Box
                    sx={{
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        backgroundColor: parseInt(params.row.sensor?.light) <= 50 ? "#FF3941" : parseInt(params.row.sensor?.light) <= 60 ? "#FFAD2C" : "#36B368",
                    }}
                />}
            </Stack>
        ),
    },
    {
        field: 'temp',
        headerName: 'Temp',
        width: 120,
        renderCell: (params) => (
            <Stack
                width={"100%"}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                mr={5}
                height="100%"
            >
                <Typography
                    color={'#44444F'}
                    fontFamily={'Roboto'}
                    fontWeight={400}
                    fontSize={12}
                >
                    {/* {params.row.sensor.temperature ? `${parseInt(params.row.sensor.temperature)}%` */}
                    <Typography fontSize={12}>{!isNaN(params.row.sensor?.temperature) ? `${parseFloat(params.row.sensor?.temperature).toFixed(1)}°F` : "-"}</Typography>
                </Typography>
                {params.row.sensor?.temperature && <Box
                    sx={{
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        backgroundColor: parseInt(params.row.sensor?.temperature) <= 50 ? "#FF3941" : parseInt(params.row.sensor?.temperature) <= 60 ? "#FFAD2C" : "#36B368",
                    }}
                />}
            </Stack>
        ),
    },
    {
        field: 'activity',
        headerName: 'Activity',
        width: 120,
        renderCell: (params) => {
            const sensorData = params.row.sensor;
            const end = new Date();
            let sosTime = 0
            let buttonTime = 0

            let eventType = 0
            const thresholdMins = 3 * 60 * 1000
            if (sensorData && sensorData.sosTime) {
                const start = new Date(sensorData.sosTime);
                sosTime = end - start;

            }
            if (sensorData && sensorData.buttonTime) {
                const start = new Date(sensorData.buttonTime);
                buttonTime = end - start;
            }

            if (sensorData && sensorData.sosTime && sosTime <= thresholdMins) {
                eventType = 1
            } else if (sensorData && sensorData.buttonTime && buttonTime <= thresholdMins) {
                eventType = 2
            }



            return (eventType == "1" ?
                <Box
                    sx={{
                        p: "2px",
                        borderRadius: '20px',
                        bgcolor: "#1E75FF4F",
                        height: '30px',
                        alignContent: 'center',
                    }}
                >
                    <Typography
                        height={"26px"}
                        width={"60px"}
                        borderRadius='20px'
                        backgroundColor="#FF3941"
                        color={'#FFFFFF'}
                        fontFamily={'Roboto'}
                        fontWeight={400}
                        fontSize={12}
                        alignContent="center"
                        textAlign={'center'}
                    >
                        SOS
                    </Typography>
                </Box>
                :
                eventType == "2" ?
                    <Box
                        sx={{
                            p: "2px",
                            borderRadius: '20px',
                            bgcolor: "#3333CC4F",
                            height: '30px',
                            alignContent: 'center',
                        }}
                    >
                        <Typography
                            height={"26px"}
                            width={"60px"}
                            borderRadius='20px'
                            backgroundColor="#36B368"
                            color={'#151D26'}
                            fontFamily={'Roboto'}
                            fontWeight={400}
                            fontSize={12}
                            alignContent="center"
                            textAlign={'center'}
                        >
                            Pressed
                        </Typography>
                    </Box> :
                    <Typography fontSize={12}>
                        None
                    </Typography>

            )
        },
    },
    {
        field: 'battery',
        headerName: 'Battery',
        width: 120,
        renderCell: (params) => {
            const battery = params.row.sensor && params.row.sensor.battery ? parseInt(params.row.sensor.battery) : null
            var formatedBattery = battery ? `${battery}%` : "-"
            return (
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'55px'}>
                    <Typography fontSize={12}>{formatedBattery}</Typography>
                    {params.row.sensor && params.row.sensor.battery && < Box
                        sx={{
                            width: 8,
                            height: 8,
                            borderRadius: '50%',
                            // backgroundColor: battery <= 3.5 ? "#FF3941" : battery <= 4.0 ? "#FFAD2C" : "transparent",
                            backgroundColor: battery <= 50 ? "#FF3941" : battery <= 60 ? "#FFAD2C" : "#36B368",
                        }}
                    />}
                </Stack>
            )
        },
    },
    {
        field: 'timestamp',
        headerName: 'Last Message',
        width: 150,
        renderCell: (params) => {
            const date = params.row.sensor && params.row.sensor.timestamp ? params.row.sensor.timestamp : null
            var formatedDate = date != null ? calculateDuration(date, moment().format("MM/DD/YY hh:mm a")) : "-"
            return (
                <Typography fontSize={12}>{formatedDate}</Typography>
            )
        },
    },
    {
        field: 'selected',
        headerName: 'Watch',
        width: 100,
        renderCell: (params) => {
            return (
                <Stack alignItems={'flex-start'} width={'100%'} pr={5}>
                    <Checkbox
                        color="primary"
                        onChange={(e) => {
                            onCheck(params.row, e.target.checked ? "1" : "0");
                        }}
                        onClick={(event) => {
                            event.stopPropagation()
                        }}
                        checked={params.row.isFavourite == "1"}
                    />
                </Stack>
            )
        },
    },
];

export const rows = [
    {
        id: 1,
        personnel: "Daniel Pacheco",
        status: "1",
        groups: [
            {
                _id: 1,
                name: "Machinery",
                color: "#0A00FF"
            },
            {
                _id: 2,
                name: "Machinery 2",
                color: "#0A00FF"
            }
        ],
        light: "80%",
        temp: "80%",
        activity: "1",
        tracker: {
            battery: "95",
            lastMessage: "11 minutes ago",
        },
        isFavourite: "1",
    },
    {
        id: 2,
        personnel: "John Smitho",
        status: "0",
        groups: [
            {
                _id: 1,
                name: "Machinery",
                color: "#0A00FF"
            },
            {
                _id: 2,
                name: "Machinery 2",
                color: "#0A00FF"
            }
        ],
        light: "80%",
        temp: "80%",
        activity: "0",
        tracker: {
            battery: "55.5",
            lastMessage: "11 minutes ago",
        },
        isFavourite: "1",
    },
    {
        id: 3,
        personnel: "Larry Barron",
        status: "1",
        groups: [
            {
                _id: 1,
                name: "Machinery",
                color: "#0A00FF"
            },
            {
                _id: 2,
                name: "Machinery 2",
                color: "#0A00FF"
            },
            {
                _id: 3,
                name: "Machinery 3",
                color: "#0A00FF"
            },
            {
                _id: 4,
                name: "Machinery 4",
                color: "#0A00FF"
            }
        ],
        light: "60%",
        temp: "60%",
        activity: "0",
        tracker: {
            battery: "3.0",
            lastMessage: "11 minutes ago",
        },
        isFavourite: "1",
    },
    {
        id: 4,
        personnel: "Alex Rodriguez",
        status: "0",
        groups: [
            {
                _id: 1,
                name: "Machinery",
                color: "#0A00FF"
            },
        ],
        light: "50%",
        temp: "50%",
        activity: "2",
        tracker: {
            battery: "4.0",
            lastMessage: "11 minutes ago",
        },
        isFavourite: "1",
    }
]

