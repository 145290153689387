import { Box, ButtonBase, Grid, Stack, Typography, Backdrop, CircularProgress, IconButton, Checkbox, Avatar, Menu } from '@mui/material';
import React, { useState, useEffect, createRef, useRef } from 'react';
import { DefaultInput } from '../../../ui/DefaultInput/index.js';
import { DefaultToggle } from '../../../ui/DefaultToggle/DefaultToggle.jsx';
import { Tabs } from '../../../ui/Tabs/index.js';
import { toolsTabs } from '../../../modules/Asset/data/toolsTabs.jsx';
import { DefaultDataGrid } from './DefaultDataGrid';
import { ConfirmAlert } from '../../../customControls/confirmAlert.js';
import api from '../../../lib/axios.js';
import { Map } from './Map.jsx';
import moment from 'moment'
import { enqueueSnackbar } from 'notistack';
import { pictureColumns, geoFenceColumns } from '../data/locationMap.jsx';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useNavigate } from 'react-router-dom';
import personnelMenuIcon from '../../../assets/images/personnelMenu.svg';
import greenBatteryIcon from '../../../assets/images/greenBatteryIcon.svg';
import { ButtonAlert } from './ButtonAlert.jsx';

export const LocationsMap = ({ personnelData, sensorData, getData }) => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(toolsTabs[0].value);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    const [documentDeleteConfirmationShouldOpen, setDocumentDeleteConfirmationShouldOpen] = useState(false);
    const [arrayData, setArrayData] = useState([]);
    const [selectedDataId, setSelectedDataId] = useState("");
    const [selectedDocumentID, setSelectedDocumentID] = useState("");
    const [addGeoFence, setAddGeoFence] = useState(false);
    const [drawData, setDrawData] = useState(null);
    const [selectedPoligon, setSelectedPoligon] = useState(null);
    const [pictureArrayData, setPictureArrayData] = useState([]);
    const tempChartRef = useRef();
    const lightChartRef = useRef();
    const [eventTime, setEventTime] = useState("");
    const [eventType, setEventType] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [selectedMenu, setSelectedMenu] = useState("0");
    const arrayMenu = [
        { id: "0", value: "Overview" },
        { id: "1", value: "More Details" },
        { id: "2", value: "Geofences" },
        { id: "3", value: "Button Alerts" },
        // { id: "4", value: "Pictures" },
    ]
    const fileRef = createRef()
    const formik = useFormik({
        initialValues: {
            submit: null,
            fenceName: '',
            isAlert: false,
            email: ''
        },
        validationSchema: Yup.object().shape({
            fenceName: Yup.string().required('Fence name is required'),
            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                var dataValues = {
                    submit: null,
                    fenceName: values.fenceName,
                    isAlert: values.isAlert,
                    email: values.email,
                }
                formik.setValues(dataValues)
                if (drawData == null) {
                    enqueueSnackbar("Please draw poligon to add geofece", {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                } else {
                    setIsLoading(true)
                    var data = await save(values.fenceName, values.isAlert, values.email);
                    setIsLoading(false)
                    if (data !== undefined && data.data.success) {
                        setStatus({ success: true });
                        setSubmitting(false);
                        getPoligon()
                    } else {
                        setStatus({ success: false });
                        setSubmitting(false);
                        enqueueSnackbar(data.data.message, {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'error'
                        });
                    }
                }

                setSubmitting(false);
            } catch (err) {
                console.log("inCATCH", err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
            }
        },
    })

    useEffect(() => {
        if (personnelData && personnelData._id != null) {
            getPoligon()
            getImageData()
            calculateDuration()
            if (tempChartRef.current) {
                pieChart('chartdiv1')
            }

            if (lightChartRef.current) {
                pieChart('chartdiv2')
            }
        }
    }, [personnelData]);

    // useEffect(() => {
    //     if (tempChartRef.current) {
    //         pieChart('chartdiv1')
    //     }

    //     if (lightChartRef.current) {
    //         pieChart('chartdiv2')
    //     }

    // }, [tempChartRef, lightChartRef]);

    useEffect(() => {
        if (addGeoFence) {
            setAddGeoFence(true)
        }
    }, [addGeoFence]);

    const getPoligon = () => {
        setIsLoading(true)
        api.get('/apis/PersonnelGeoFencings/byPersonnel/' + personnelData._id).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayData(resData)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function save(name, isAlert, email) {
        let arrayData = []
        const data = drawData;
        if (data.features.length > 0) {
            if (selectedPoligon == null) {
                data.features.map(res => {
                    var feature = {
                        "email": email,
                        "notification": isAlert ? "1" : "0",
                        "name": name,
                        "region": {
                            "type": "Polygon",
                            "coordinates":
                                res.geometry.coordinates
                        },
                        "mode": activeTab,
                    }
                    arrayData.push(feature)
                })
                if (arrayData.length > 0) {
                    await addData(arrayData)
                }
            } else {
                var updatedData = data.features.find(obj => obj.id == selectedPoligon._id)
                if (updatedData) {
                    await updateData(name, updatedData, selectedPoligon, email, isAlert)
                }
            }
        }
    }

    async function addData(arrayData) {
        var data = {
            personnelID: personnelData._id,
            arrayData: arrayData
        }
        return await api.post('/apis/PersonnelGeoFencings/', data).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var dataValues = {
                    submit: null,
                    fenceName: "",
                    isAlert: false,
                    email: null
                }
                formik.setValues(dataValues)
                setDrawData(null)
                setAddGeoFence(false)

                enqueueSnackbar('Poligon added', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                getPoligon()
            }
        }).catch(function (error) {
            console.log(error);
        });
    }


    async function updateData(name, feature, selectedPoligon, email, isAlert) {
        var data = {
            "name": name,
            "email": email,
            "notification": isAlert ? "1" : "0",
            "region": {
                "type": "Polygon",
                "coordinates":
                    feature.geometry.coordinates
            },
        }
        return await api.put('/apis/PersonnelGeoFencings/' + selectedPoligon._id, data).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                // console.log("Put", resData);
                var dataValues = {
                    submit: null,
                    fenceName: "",
                    isAlert: false,
                    email: null
                }
                formik.setValues(dataValues)
                setSelectedPoligon(null)
                setDrawData(null)
                setAddGeoFence(false)

                enqueueSnackbar('Poligon updated', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                getPoligon()
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    function deleteRecord() {
        setIsLoading(true)
        api.delete('/apis/PersonnelGeoFencings/' + selectedDataId).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("Poligon deleted", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                setSelectedDataId("")
                getPoligon()
            } else {
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });

            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deleteRecord()
        } else {
            setSelectedDataId("")
        }
    }

    const onDeleteClick = (row) => {
        setSelectedDataId(row._id)
        setDeleteConfirmationShouldOpen(true)
    };

    const onEditClick = (row) => {
        setSelectedPoligon(row)
        var value = {
            fenceName: row.name,
            isAlert: row.notification == "1" ? true : false,
            email: row.email
        }
        formik.setValues(value)
        setAddGeoFence(true)
    };

    const handleChangeTab = (tab) => {
        setActiveTab(tab);
    };

    const onDrawGeofence = (data) => {
        setDrawData(data)
    };


    const onDocumentDeleteClick = (row) => {
        setSelectedDocumentID(row._id)
        setDocumentDeleteConfirmationShouldOpen(true)
    };


    function deleteDocumnet() {
        setIsLoading(true)
        api.delete('/apis/PersonnelImages/' + selectedDocumentID).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("Picture deleted", { anchorOrigin: { horizontal: 'right', vertical: 'top' }, variant: 'success' });
                setSelectedDocumentID("")
                getImageData()
            } else {
                enqueueSnackbar(res.data.message, { anchorOrigin: { horizontal: 'right', vertical: 'top' }, variant: 'error' });
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateDocumentConfirmAlert = (value) => {
        if (value) {
            deleteDocumnet()
        } else {
            setSelectedDocumentID("")
        }
    }

    const pieChart = (chartID) => {
        am5.addLicense("AM5C349425111");
        am5.array.each(am5.registry.rootElements, function (root) {
            if (root && root.dom && root.dom.id == chartID) {
                root.dispose();
            }
        });
        let root = am5.Root.new(chartID);


        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(am5percent.PieChart.new(root,
            {
                innerRadius: 50,
                layout: root.verticalLayout
            }));

        let series = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "size",
            categoryField: "sector",
        }));

        series.slices.template.set("tooltipText", "");
        series.slices.template.set("toggleKey", "none");
        series.labels.template.set("visible", false);
        series.ticks.template.set("visible", false);

        let label = root.tooltipContainer.children.push(am5.Label.new(root, {
            x: am5.p50,
            y: am5.p50,
            centerX: am5.p50,
            centerY: am5.p50,
            fill: am5.color(0x000000),
            fontSize: '17px'
        }));


        if (chartID == "chartdiv1") {
            series.get("colors").set("colors", [
                am5.color(0x1E75FF),
                am5.color(0xE6E7E8),
            ]);
            var temp = sensorData.temperature ? parseFloat(sensorData.temperature) : 0
            var other = 100 - temp
            series.data.setAll([
                { sector: "", size: temp },
                { sector: "", size: other },
            ]);
            label.set("text", !isNaN(sensorData.temperature) ? `${parseFloat(sensorData.temperature).toFixed(1)}°F` : "0%");
        } else {
            series.get("colors").set("colors", [
                am5.color(0x1E75FF),
                am5.color(0xE6E7E8),
            ]);
            var other2 = 100 - (parseInt(sensorData.light))
            series.data.setAll([
                { sector: "", size: parseInt(sensorData.light) },
                { sector: "", size: other2 },
            ]);
            label.set("text", !isNaN(sensorData.light) ? `${parseInt(sensorData.light)}%` : "0%");
        }
        series.appear(1000, 100);
    }

    async function uploadFiles(file) {
        setIsLoading(true)
        if (!file.type.startsWith('image/')) {
            enqueueSnackbar("Upload the file in the correct format", {
                anchorOrigin: { horizontal: 'right', vertical: 'top' },
                variant: 'error'
            });
            setIsLoading(false)
            return;
        }
        const formData = new FormData();
        formData.append("file", file);

        formData.append("userName", localStorage.getItem("fullName"));
        api({
            method: "POST",
            url: '/apis/PersonnelImages/uploadimage/' + personnelData._id,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then((res) => {
                if (res.data.success) {
                    enqueueSnackbar("Picture uploaded", { anchorOrigin: { horizontal: 'right', vertical: 'top' }, variant: 'success' });
                    getImageData()
                } else {
                    enqueueSnackbar(res.data.message, { anchorOrigin: { horizontal: 'right', vertical: 'top' }, variant: 'error' });
                }
                setIsLoading(false)
            }).catch(function (error) {
                console.log(error);
            });
    }

    async function getImageData() {
        // setIsLoading(true)
        return await api.get('/apis/PersonnelImages/byPersonnel/' + personnelData._id).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setPictureArrayData(resData)
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function changeStatus(status, id) {
        setIsLoading(true)
        var data = {
            status: status,
        }
        return await api.put('/apis/PersonnelSensors/changestatus/' + id, data).then((res) => {
            if (res.data.success) {
                // console.log("data", res.data)
                getData()
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    function calculateDuration() {

        const end = new Date();
        let sosTime = 0
        let buttonTime = 0
        let durationMs = 0

        let eventType = 0
        let durationString = "";
        if (sensorData) {


            const thresholdMins = 3 * 60 * 1000
            if (sensorData.sosTime) {
                const start = new Date(sensorData.sosTime);
                sosTime = end - start;

            }
            if (sensorData.buttonTime) {
                const start = new Date(sensorData.buttonTime);
                buttonTime = end - start;
            }

            if (sensorData.sosTime && sosTime <= thresholdMins) {
                durationMs = sosTime
                eventType = 1
            } else if (sensorData.buttonTime && buttonTime <= thresholdMins) {
                durationMs = buttonTime
                eventType = 2
            }
            const minutes = Math.floor((durationMs / (1000 * 60)) % 60);
            if (durationString.trim() === "" && eventType != 0) {
                durationString = "Just Now";
            } else {
                durationString = "No Activity";
            }
            if (minutes > 0) {
                durationString = minutes + (minutes === 1 ? " min " : " mins ");
                durationString += " ago";
            }
        }
        setEventTime(durationString)
        setEventType(eventType)
    }

    function calculateTimeDuration(startTime, endTime) {

        const start = new Date(startTime);
        const end = new Date(endTime);

        const durationMs = end - start;

        const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((durationMs / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((durationMs / (1000 * 60)) % 60);
        const seconds = Math.floor((durationMs / 1000) % 60);




        let durationString = "";
        if (days > 0) {
            durationString += days + (days === 1 ? " Day " : " Days ");
        }
        if (hours > 0) {
            durationString += hours + (hours === 1 ? " hr " : " hrs ");
        }
        if (minutes > 0) {
            durationString += minutes + (minutes === 1 ? " min " : " mins ");
        }
        if (durationString.trim() === "") {
            durationString = "Just Now";
        }
        return durationString.trim();
    }

    return (
        <>
            <Stack
                flex={1}
                direction={'row'}
                sx={{
                    border: '1px solid #E2E2EA',
                    borderRadius: '8px 8px 8px 8px',
                    bgcolor: '#FFFFFF',
                }}
                mb={1}
                height={'100%'}
                width={'calc(100% + 24px)'}
            >
                <Stack gap={3} borderRight={'1px solid #E2E2EA'} width={430} flexShrink={0} sx={{
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '4px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        width: '4px',
                        bgcolor: 'grey.400',
                    },
                }}>
                    <Box
                        pt={2}
                        px={2}
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center'
                        }}
                    >
                        <IconButton
                            sx={{
                                width: '20px',
                                height: '20px',
                            }}
                            onClick={(e) => {
                                setAnchorEl(e.currentTarget);
                            }}
                        >
                            <img src={personnelMenuIcon} alt="Menu Icon" />
                        </IconButton>
                        <Menu
                            id='groups'
                            anchorEl={anchorEl}
                            open={open}
                            onClose={(e) => {
                                setAnchorEl(null);
                            }}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                                sx: {},
                            }}
                            PaperProps={{
                                sx: {
                                    boxShadow: 'unset',
                                    borderRadius: '0 0 5px 5px',
                                    border: '1px solid #E2E2EA',
                                    borderTop: 'none',
                                    width: 150,
                                },
                            }}
                        >
                            {arrayMenu.map(obj => {
                                return <Stack px={2} py={0.6} key={obj.id} onClick={() => {
                                    setSelectedMenu(obj.id)
                                    setAnchorEl(null);
                                }}>
                                    <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={14} sx={{
                                        cursor: 'pointer',
                                    }}>
                                        {obj.value}
                                    </Typography>
                                </Stack>
                            })}
                        </Menu>
                    </Box>

                    <Stack gap={3} height={'100%'}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Avatar
                                alt="User Avatar"
                                src={personnelData?.path}
                                sx={{
                                    width: 190,
                                    height: 190
                                }}
                            />
                        </Box>
                        {selectedMenu == "0" && <Grid container spacing={2.5} px={2} >
                            <Grid item xs={4}>
                                <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={12}>Status:</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack direction={'row'} gap={3} alignItems={'center'}>
                                    <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>{sensorData != null && sensorData.state == 1 ? "Online" : "Offline"}</Typography>
                                    <Box
                                        sx={{
                                            width: 8,
                                            height: 8,
                                            borderRadius: '50%',
                                            backgroundColor: sensorData != null && sensorData.state == "1" ? "green.main" : "red.main",
                                        }}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                    {sensorData != null && sensorData.latitude != null && sensorData.latitude != "" && sensorData.latitude != "-" && sensorData.timestamp != null && sensorData.timestamp !== "" ? calculateTimeDuration(moment(sensorData.timestamp), moment().format("MM/DD/YY hh:mm a")) : "-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack gap={0.5}>
                                    <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={12}>{personnelData != null && personnelData.firstName ? personnelData.firstName : "-"}</Typography>
                                    <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                        First
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack gap={0.5}>
                                    <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={12}>{personnelData != null && personnelData.lastName ? personnelData.lastName : "-"}</Typography>
                                    <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                        Last
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack gap={0.5}>
                                    <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={12}>{personnelData != null && personnelData.mobile ? personnelData.mobile : personnelData.user != null && personnelData.user.mobile ? personnelData.user.mobile : "-"}</Typography>
                                    <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                        Mobile
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack gap={0.5}>
                                    <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={400} fontSize={12}>{personnelData?.sensor?.address || "-"}</Typography>
                                    <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                        Location
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>}
                        {/* More Detail */}
                        {selectedMenu == "1" && <Stack width={'100%'} px={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Stack gap={0.5}>
                                        <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={12}>{personnelData != null && personnelData.firstName ? personnelData.firstName : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                            First Name
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack gap={0.5}>
                                        <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={12}>{personnelData != null && personnelData.lastName ? personnelData.lastName : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                            Last Name
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack gap={0.5}>
                                        <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={12}>{personnelData != null && personnelData.mobile ? personnelData.mobile : personnelData.user != null && personnelData.user.mobile ? personnelData.user.mobile : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                            Mobile
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack gap={0.5}>
                                        <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={12}
                                            sx={{ textDecoration: personnelData != null && personnelData.sensorNumber ? 'underline' : "none", cursor: personnelData != null && personnelData.sensorNumber ? 'pointer' : 'none' }}
                                        // onClick={() => {
                                        //     if (personnelData != null && personnelData.sensorNumberID) {
                                        //         navigate("/devices/detail", { state: { id: personnelData.sensorNumberID, assetName: personnelData.assetName } })
                                        //     }
                                        // }}
                                        >
                                            {personnelData != null && personnelData.sensorNumber ? personnelData.sensorNumber : "-"}</Typography>

                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                            Device 1
                                        </Typography>
                                    </Stack>
                                </Grid>
                                {personnelData != null && personnelData.sensor != null && <Grid item xs={6}>
                                    <Stack gap={0.5}>
                                        <Checkbox
                                            sx={{
                                                height: '16px',
                                                width: '16px',
                                            }}
                                            checked={personnelData.sensor.status == "1"}
                                            onChange={(e) => {
                                                changeStatus(e.target.checked ? "1" : "2", personnelData.sensorNumberID)
                                            }}
                                        />
                                        <Typography fontSize={12} color={'#63C467'} fontFamily={'Roboto'} fontWeight={500} pl={0.7}>
                                            $
                                        </Typography>
                                    </Stack>
                                </Grid>
                                }
                                <Grid item xs={6}>
                                    <Stack gap={0.5}>
                                        <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={12}>{personnelData != null && personnelData.email ? personnelData.email : personnelData.user != null && personnelData.user.email ? personnelData.user.email : "-"}</Typography>

                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                            Email
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>}
                        {/* Button Alert */}
                        {selectedMenu == "3" && <Stack height={'100%'}><ButtonAlert
                            personnelData={personnelData}
                            sensorData={sensorData}
                            getData={getData}
                        /></Stack>}
                        {/* Add Picture */}
                        {selectedMenu == "4" && <Stack height={'100%'}><DefaultDataGrid rows={pictureArrayData} columns={pictureColumns(onDocumentDeleteClick, fileRef, uploadFiles)} disablePagination onRowClick={(e) => {
                            if (e.row.path) {
                                window.open(e.row.path, '_blank');
                            }
                        }} sx={{ px: 2 }}></DefaultDataGrid></Stack>}
                        {/* Add GeoFence */}
                        {addGeoFence && <FormikProvider value={formik}> <Stack px={2} gap={1} component={'form'}
                            onSubmit={formik.handleSubmit}>
                            <Stack direction={'row'} gap={1} alignItems={'center'}>
                                <Typography width={80} flexShrink={0} color={'#A0A3A6'} fontSize={12} fontFamily={'Roboto'} fontWeight={400} >
                                    Fence Name
                                </Typography>
                                <DefaultInput
                                    defaultValue={formik.values.fenceName}
                                    placeholder={'Enter name...'}
                                    name={'fenceName'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.fenceName && formik.errors.fenceName)}
                                    helperText={formik.touched.fenceName && formik.errors.fenceName} />
                            </Stack>
                            <Stack direction={'row'} gap={1} alignItems={'center'}>
                                <Typography width={80} flexShrink={0} color={'#A0A3A6'} fontSize={12} fontFamily={'Roboto'} fontWeight={400} >
                                    Select Tool
                                </Typography>
                                <Stack
                                    flex={1}
                                    sx={{
                                        height: 30,
                                        // border: '1px solid #E2E2EA',
                                        // borderRadius: '6px',
                                    }}
                                >
                                    <Tabs data={toolsTabs} setActiveTab={handleChangeTab} activeTab={activeTab} inline small />
                                </Stack>
                            </Stack>
                            <Stack direction={'row'} gap={1} alignItems={'center'}>
                                <Typography width={80} flexShrink={0} color={'#A0A3A6'} fontSize={12} fontFamily={'Roboto'} fontWeight={400} >
                                    Alerts
                                </Typography>
                                <Stack
                                    direction={'row'}
                                    gap={1}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                    flex={1}
                                    sx={{
                                        height: 40,
                                        border: '1px solid #E2E2EA',
                                        borderRadius: '6px',
                                        pl: 2,
                                        pr: 1,
                                    }}
                                >
                                    <Typography fontSize={14}>Activate Alerts</Typography>
                                    <DefaultToggle name={'isAlert'} />
                                </Stack>
                            </Stack>
                            <Stack direction={'row'} gap={1} alignItems={'center'}>
                                <Typography width={80} flexShrink={0} color={'#A0A3A6'} fontSize={12} fontFamily={'Roboto'} fontWeight={400} >
                                    Send To
                                </Typography>
                                <DefaultInput
                                    defaultValue={formik.values.email}
                                    placeholder={'Enter email'}
                                    name={'email'}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.email && formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Stack>
                            <Stack direction={'row'} gap={1} justifyContent={'flex-end'}>
                                <ButtonBase
                                    sx={{
                                        py: 1,
                                        px: 3,
                                        border: '1px solid #E2E2EA',
                                        bgcolor: '#EFEFEF',
                                        borderRadius: '5px',
                                    }}
                                    onClick={() => {
                                        setDrawData(null)
                                        setAddGeoFence(false)
                                        setSelectedPoligon(null)
                                        var value = {
                                            fenceName: '',
                                            isAlert: false,
                                            email: null
                                        }
                                        formik.setValues(value)
                                    }}
                                >
                                    <Typography fontSize={13} fontFamily={'Roboto'} fontWeight={700} color={'#151D26'}>
                                        Cancel
                                    </Typography>
                                </ButtonBase>
                                <ButtonBase
                                    sx={{
                                        py: 1,
                                        px: 3,
                                        border: '1px solid #E2E2EA',
                                        bgcolor: '#FCFCFE',
                                        borderRadius: '5px',
                                    }}
                                    disabled={formik.isSubmitting}
                                    type="submit"
                                >
                                    <Typography fontSize={13} fontFamily={'Roboto'} fontWeight={700} color={'#151D26'}>
                                        Save
                                    </Typography>
                                </ButtonBase>
                            </Stack>
                        </Stack> </FormikProvider>}
                        {addGeoFence ? '' : selectedMenu == "2" && <Stack height={'100%'}><DefaultDataGrid rows={arrayData} columns={geoFenceColumns(personnelData.serialNumber, onDeleteClick, onEditClick, setAddGeoFence, formik)} disablePagination
                            onRowClick={(e) => {
                                if (e.row.path) {
                                    window.open(e.row.path, '_blank');
                                }
                            }} sx={{ px: 2 }} /></Stack>}

                        {/* <Box flexGrow={1} /> */}
                        <Box px={2} py={"16px"} bgcolor={'#F6F8FA'}>
                            <Grid container spacing={2.5}>
                                <Grid item xs={12}>
                                    <Stack direction={'row'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Typography color={'#262B40'} fontFamily={'Roboto'} fontSize={12}>Ambient Sensors</Typography>
                                        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                                            <Typography color={'#151D26'} fontFamily={'Roboto'} fontSize={12} mr={'10px'}>{sensorData != null && sensorData.battery ? `${sensorData.battery}%` : "-"}</Typography>
                                            <Box component={'img'} src={greenBatteryIcon} sx={{ width: "18px", height: "18px" }}></Box>
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                    <Stack gap={'5px'} alignItems={'center'} >
                                        <Stack mt={'11px'} >
                                            <Box
                                                sx={{
                                                    height: '100px',
                                                    width: '100px',
                                                }}
                                                id={'chartdiv1'}
                                                ref={tempChartRef}
                                            />

                                        </Stack>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400} sx={{ pt: 1.5 }}>
                                            Temp
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                    <Stack gap={"5px"} alignItems={'center'}>
                                        <Stack mt={'11px'}>
                                            <Box
                                                sx={{
                                                    height: '100px',
                                                    width: '100px',
                                                }}
                                                id={'chartdiv2'}
                                                ref={lightChartRef}
                                            />

                                        </Stack>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400} sx={{ pt: 1.5 }}>
                                            Light
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4} >
                                    <Stack gap={0.5} alignItems={'center'}>
                                        <Stack mt={'11px'}>
                                            <Box
                                                sx={{
                                                    height: '100px',
                                                    width: '100px',
                                                    border: '3px solid #DDDDDE',
                                                    bgcolor: eventType == 1 ? "#FE5F57" : eventType == 2 ? "#63C467" : "#F6F7F7",
                                                    alignContent: 'center',
                                                    borderRadius: "50%"
                                                }}
                                            >
                                                <Typography fontSize={15} color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} textAlign={'center'}>
                                                    {eventType == 1 ? "SOS" : eventType == 2 ? "Pressed" : "Button"}
                                                </Typography>
                                            </Box>
                                            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400} sx={{ pt: 1.5 }} textAlign={'center'}>
                                                {sensorData != null && eventTime}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Stack>
                </Stack>
                <Map sensorData={sensorData} personnelData={personnelData} arrayData={arrayData} isDrawGeoFence={addGeoFence} onDrawGeofence={onDrawGeofence} mode={activeTab} selectedPoligon={selectedPoligon} />
            </Stack >
            {deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                updateConfirmAlert={(e, value) => {
                    setDeleteConfirmationShouldOpen(false)
                    updateConfirmAlert(value)
                }} />)}
            {documentDeleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                updateConfirmAlert={(e, value) => {
                    setDocumentDeleteConfirmationShouldOpen(false)
                    updateDocumentConfirmAlert(value)
                }} />)}
            <Backdrop style={{ zIndex: 4 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};
